import { KEYS } from './constants-keys';

export const APP_ENV_TYPE = {
	DEV: 'development',
	STAGE: 'staging',
	PROD: 'production',
};

const AppConfig = {
	ENV: APP_ENV_TYPE.PROD,
	API_SERVER: 'https://api.apoc.day/api/v2',
	CHAT_URL: 'https://chat.apoc.day',
	CHAT_SERVER: 'https://chat.apoc.day',
	FRONT_HOST: 'https://www.apoc.day/',
	AUTHOR_HOST: 'https://studio.apoc.day/#/',
	FILE_SERVER: 'https://cdn.apoc.day/',
	TRACKER_URL: 'https://cdn.apoc.day/js/tracker.js?20240415',
	KEYS,
};
export default AppConfig;
