import { ThumbNailEntityDto } from '@/api/dto/file.dto';
import { CATEGORY_TYPE_CODE, CONTENT_EVAL_LIKEBAD_CODE, STATE_YN } from '@/types';
import {
	ContentBodyEntity,
	ContentCollaboRecEntity,
	ContentCommentEntity,
	ContentEntity,
	ContentEvalEntity,
	ContentPlayRecEntity,
	ContentPriceEntity,
	ContentTagRecEntity,
} from '../model/content.entity';
import { BasicListDto } from './request.dto';

/**
 * 콘텐트 리스트 검색용 DTO
 */
export class SearchContentListDto extends BasicListDto {
	contentIdx = '';
	contentName = '';
	notTemplate = false; // 템플릿에 등록된 것을 제외한
	openTypeCode: string | undefined = undefined;
	chargeYn: STATE_YN | undefined = undefined;
	kidYn: STATE_YN | undefined = undefined;
	onlyMine: boolean | undefined = undefined; // 내가 참여한 채널에 작품을 제외한 온전히 나의 작품만
	showShared: boolean | undefined = undefined; // 내가 협업을 통해 공유한 콘텐트
	regrUserIdx: string | undefined = undefined;
	creatorView: boolean | undefined = undefined; // 작가 페이지 조회 여부
	categoryIdx: string | undefined = undefined;
	categoryCode: string | undefined = undefined;
	categoryTypeCode: CATEGORY_TYPE_CODE = CATEGORY_TYPE_CODE.CONTENT;
	likeCategoryCode: string | undefined = undefined; // 연관 카테고리
	categoryTab: string | undefined = undefined; // 화면에 있는 탭 정보
	targetIdx: string | undefined = undefined;
	myContents: boolean | undefined = undefined;
}

/**
 * 콘텐트 바디 리스트 검색용 DTO
 */
export class SearchContentBodyListDto extends BasicListDto {
	contentIdx: string | undefined = undefined;
	contentBodyMetaMame: string | undefined = undefined;
	contentBodyMetaValue: string | undefined = undefined;
	regrUserIdx: string | undefined = undefined;
}

/**
 * 콘텐트 협업 리스트 검색용 DTO
 */
export class SearchContentCollaboRecListDto extends BasicListDto {
	contentCollaboSeq = 0;
	contentIdx: string | undefined = undefined;
	collaborUserIdx: string | undefined = undefined;
	regrUserIdx: string | undefined = undefined;
}

/**
 * 콘텐트 댓글 리스트 검색용 DTO
 */
export class SearchContentCommentListDto extends BasicListDto {
	contentCommentIdx: string | undefined = undefined;
	contentIdx: string | undefined = undefined;
	hirankContentCommentIdx: string | undefined = undefined;
	regrUserIdx: string | undefined = undefined;
}

/**
 * 콘텐트 평가 리스트 검색용 DTO
 */
export class SearchContentEvalListDto extends BasicListDto {
	contentEvalTargetIdx: string | undefined = undefined;
	contentEvalTypeCode: string | undefined = undefined;
	contentEvalUserIdx: string | undefined = undefined;
	contentEvalValue: string | undefined = undefined;
	regrUserIdx: string | undefined = undefined;
}

/**
 * 콘텐트 플레이 내역 검색용 DTO
 */
export class SearchContentPlayRecListDto extends BasicListDto {
	contentPlaySeq = 0;
	contentIdx: string | undefined = undefined;
	regrUserIdx: string | undefined = undefined;
	expireDt: Date | null = null;
	useDt: Date | null = null;
	userYn: STATE_YN = STATE_YN.N;
	contentName: string | undefined = undefined;
	notTemplate = false;
}

/**
 * 콘텐트 태그 리스트 검색용 DTO
 */
export class SearchContentTagRecListDto extends BasicListDto {
	contentIdx: string | undefined = undefined;
	regrUserIdx: string | undefined = undefined;
	tagName: string | undefined = undefined;
}

/**
 * 콘텐트 확장형 DTO
 */
export class ContentEntityDto extends ContentEntity {
	//반환 총갯수
	totalCount = 0;
	//플레이 한 수
	playCount = 0;
	//팔로워 수
	followerCount = 0;
	//사용자 id
	userIdx = '';
	//사용자 이름
	userName: string | undefined = undefined;
	//사용자 닉네임
	userNicknm: string | undefined = undefined;
	//사용자 프로필
	userProfileImg: string | undefined = undefined;
	//태그목록 JSON
	tagList: string | undefined = undefined;
	//내가 즐겨찾기 했는지
	favContent: STATE_YN | null = null;
	//내가 팔로워 했는지
	followerUser: STATE_YN | undefined = undefined;
	//내가 좋아하는지
	likeContent: STATE_YN | undefined = undefined;
	//내가 싫어하는지
	badContent: STATE_YN | undefined = undefined;
	//카테고리 고유번호
	categoryIdx: string | undefined = undefined;
	//카테고리 이름
	categoryName: string | undefined = undefined;
	//카테고리 이름(영어)
	categoryEnName: string | undefined = undefined;
	// 카테고리 코드
	categoryCode: string | undefined = undefined;
	//채널 고유번호
	channelIdx: string | undefined = undefined;
	//채널 유저리스트
	channelUserList: string[] = [];
	//채널명
	channelName: string | undefined = undefined;
	//채널설명
	channelDesc: string | undefined = undefined;
	// 채널색상
	channelColor: string | undefined = undefined;
	//카테고리 타입 코드
	parentCategoryTypeCode: string | undefined = undefined;
	//복사 카운트
	copyCount = 0;
	// 댓글 카운트
	commentCount = 0;
	//콘텐트 메인 썸네일
	contentMainImg: string | undefined = undefined;
	//알람타입
	alarmType: string | undefined = undefined;
	// 콘텐츠 다중 삭제를 위한 contentIdxList 집합
	contentIdxList: string[] | undefined = undefined;
	// 채널 왕관 사용자
	crownUserIdxList: string | undefined = undefined;
	// 콘텐츠 왕관 사용자
	collaboCrownUserIdxList: string | undefined = undefined;
	// 공유 페이지 시작 리스트
	shareStartPageList: string[] | undefined = undefined;
	shortUrl: string | undefined = undefined;
	// 템플릿 여부
	templateYn: string | undefined = undefined;
	// 화면에서의 썸네일
	canvasThumbNail: ThumbNailEntityDto | null = null;
	// 화면에서 input한 썸네일 파일
	contentMainImgFile: File | null = null;
	// 저장 유무
	saveYn: string | undefined = undefined;
	// 콘텐츠 열 번호
	contentRowNumber: string | undefined = undefined;
}

/**
 * 콘텐트 바디 확장형 DTO
 */
export class ContentBodyEntityDto extends ContentBodyEntity {
	//반환 총갯수
	totalCount = 0;
}

/**
 * 컨텐트 협업 내역 확장형 DTO
 */
export class ContentCollaboRecEntityDto extends ContentCollaboRecEntity {
	//반환 총갯수
	totalCount = 0;
}

/**
 * 컨텐트 댓글 확장형 DTO
 */
export class ContentCommentEntityDto extends ContentCommentEntity {
	// 반환 총갯수
	totalCount = 0;
	// 유저 닉네임
	userNicknm: string | undefined = undefined;
	// 유저 이름
	userName: string | undefined = undefined;
	// 유저 고유번호
	userIdx: string | undefined = undefined;
	//내가 좋아요 했는지
	likeComment: CONTENT_EVAL_LIKEBAD_CODE | undefined = undefined;
	//유저 프로필
	userProfileImg: string | undefined = undefined;
	//대댓글 총 갯수
	belowCommentCount = 0;
	//좋아요 갯수
	likeCount = 0;
}

/**
 * 컨텐트 평가 확장형 DTO
 */
export class ContentEvalEntityDto extends ContentEvalEntity {
	//반환 총갯수
	totalCount = 0;
}

/**
 * 컨텐트 플레이 내역 확장형 DTO
 */
export class ContentPlayRecEntityDto extends ContentPlayRecEntity {
	//반환 총갯수
	totalCount = 0;
	//플레이 한 수
	playCount = 0;
	// 플레이 한 날짜
	playRegDt: Date | null = null;
	param: any;
	//콘텐트 메인 썸네일
	contentMainImg: string | undefined = undefined;
	//사용자 id
	userIdx = '';
	//사용자 닉네임
	userNicknm: string | undefined = undefined;
	//사용자 프로필
	userProfileImg: string | undefined = undefined;
	// 콘텐츠 왕관 사용자
	collaboCrownUserIdxList: string | undefined = undefined;
	// 템플릿 여부
	templateYn: string | undefined = undefined;
}

/**
 * 컨텐트 금액 확장형 DTO
 */
export class ContentPriceEntityDto extends ContentPriceEntity {
	//반환 총갯수
	totalCount = 0;
}

/**
 * 컨텐트 태그 내역 확장형 DTO
 */
export class ContentTagRecEntityDto extends ContentTagRecEntity {
	//반환 총갯수
	totalCount = 0;
}
