<script lang="ts">
import { getPopupList, PopupInterface } from '@/api/admin';
import AppConfig from '@/constants';
import { POPUP_MODE } from '@/stores/state-store';
import { initStore } from '@/stores/store-manager';
import { LOADING_TYPE, STATE_YN } from '@/types';
import { getApiClient } from '@/utils/apiClient';
import { getCookie } from 'typescript-cookie';
import { defineComponent, onMounted, onUpdated, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import Modal from '@/components/admin/Modal.vue';
import ContentRenamePopup from '@/components/popup/ContentRenamePopup.vue';
import ContentsCopyPopup from '@/components/popup/ContentsCopyPopup.vue';
import ContentsOptionModal from '@/components/popup/ContentsOptionModal.vue';
import CopyrightPopup from '@/components/popup/CopyrightPopup.vue';
import EditChannelModal from '@/components/popup/EditChannelModal.vue';
import MakePopup from '@/components/popup/MakePopup.vue';
import ReportPopup from '@/components/popup/ReportPopup.vue';
import MobileMenuPopup from '@/components/popup/MobileMenuPopup.vue';
import ApocLink from '@/components/common/ApocLink.vue';
import ContentsSelectModal from '@/components/popup/ContentsSelectModal.vue';
import LangSelectModal from '@/components/popup/LangSelectModal.vue';
import InspectionPopup from '@/components/popup/InspectionPopup.vue';
import ContentsPopup from '@/components/popup/ContentsPopup.vue';
import FollowModal from '@/components/popup/FollowModal.vue';
import ContentReportPopup from '@/components/popup/ContentReportPopup.vue';
import ContentsMoreModal from '@/components/popup/ContentsMoreModal.vue';
import { loadApiGauge } from '@/utils/utils';

export default defineComponent({
	name: 'PopupManager',
	components: {
		ContentsMoreModal,
		ContentsPopup,
		InspectionPopup,
		ContentsSelectModal,
		LangSelectModal,
		FollowModal,
		Modal,
		ContentsCopyPopup,
		ContentsOptionModal,
		CopyrightPopup,
		EditChannelModal,
		MakePopup,
		MobileMenuPopup,
		ReportPopup,
		ApocLink,
		ContentRenamePopup,
		ContentReportPopup,
	},

	setup() {
		const { t } = useI18n();
		const storeManager = initStore();
		const apiClient = getApiClient(AppConfig.API_SERVER, storeManager);
		const isMobileMenuPopup = ref<boolean>(false);
		const isContentsOptionModal = ref<boolean>(false);
		const isContentsSelectModal = ref<boolean>(false);
		const isLangSelectModal = ref<boolean>(false);
		const isFollowModal = ref<boolean>(false);
		const isCopyrightPopup = ref<boolean>(false);
		const isEditChannelModal = ref<boolean>(false);
		const isReportPopup = ref<boolean>(false);
		const isContentReportPopup = ref<boolean>(false);
		const isMakePopup = ref<boolean>(false);
		const isContentsCopyPopup = ref<boolean>(false);
		const isContentsRenamePopup = ref<boolean>(false);
		const isInspectionPopup = ref<boolean>(false);
		const adminPopupList = ref<PopupInterface[]>([]);
		const adminPopupOpenFlag = ref(true);
		const isContentsPopup = ref<boolean>(false);
		const isContentsMoreModal = ref<boolean>(false);

		const closePopup = (): void => {
			window.document.body.style.removeProperty('width');
			window.document.getElementsByTagName('header')[0].style.removeProperty('width');
			storeManager.stateStore.setPopupMode(POPUP_MODE.NONE);
		};

		const onCloseAdminPopup = (e: MouseEvent): void => {
			adminPopupOpenFlag.value = false;
		};

		const getReleaseNoteData = () => {
			storeManager.dataStore.addPageApiTotalCount(1);
			getPopupList(apiClient, {
				popupCategory: 'release',
			})
				.then(async dt => {
					storeManager.dataStore.addLoadedCount(1);
					// console.log('getPopupList: ', storeManager.dataStore.pageApiTotalCount, storeManager.dataStore.loadedCount);
					loadApiGauge(LOADING_TYPE.ROLL, '', '');
					if (dt) {
						adminPopupList.value = dt.filter(
							f => f.openYn === STATE_YN.Y && f.popupCategory === 'release' && getCookie(`popup_flag_${f.seq}`) !== 'close',
						);
					}
				})
				.catch(() => {
					storeManager.dataStore.addLoadedCount(1);
					// console.log('getPopupList: ', storeManager.dataStore.pageApiTotalCount, storeManager.dataStore.loadedCount);
					loadApiGauge(LOADING_TYPE.ROLL, '', '');
				});
		};

		onMounted(() => {
			if (!storeManager.stateStore.directContents) {
				getReleaseNoteData();
			}
		});

		onUpdated(() => {
			isContentsOptionModal.value = storeManager.stateStore.popupMode === POPUP_MODE.CONTENTS_OPTION_MODAL;
			isContentsSelectModal.value = storeManager.stateStore.popupMode === POPUP_MODE.CONTENTS_SELECT_MODAL;
			isFollowModal.value = storeManager.stateStore.popupMode === POPUP_MODE.FOLLOW_MODAL;
			isLangSelectModal.value = storeManager.stateStore.popupMode === POPUP_MODE.LANG_SELECT_MODAL;
			isMobileMenuPopup.value = storeManager.stateStore.popupMode === POPUP_MODE.MOBILE_MENU_POPUP;
			isCopyrightPopup.value = storeManager.stateStore.popupMode === POPUP_MODE.COPYRIGHT_POPUP;
			isEditChannelModal.value = storeManager.stateStore.popupMode === POPUP_MODE.CHANNEL_MODAL;
			isReportPopup.value = storeManager.stateStore.popupMode === POPUP_MODE.REPORT_POPUP;
			isMakePopup.value = storeManager.stateStore.popupMode === POPUP_MODE.MAKE_POPUP;
			isContentsCopyPopup.value = storeManager.stateStore.popupMode === POPUP_MODE.CONTENTS_COPY;
			isContentsRenamePopup.value = storeManager.stateStore.popupMode === POPUP_MODE.CONTENTS_RENAME;
			isInspectionPopup.value = storeManager.stateStore.popupMode === POPUP_MODE.INSPECTION_MODAL;
			isContentsPopup.value = storeManager.stateStore.popupMode === POPUP_MODE.CONTENTS_POPUP;
			isContentReportPopup.value = storeManager.stateStore.popupMode === POPUP_MODE.CONTENT_REPORT_POPUP;
			isContentsMoreModal.value = storeManager.stateStore.popupMode === POPUP_MODE.CONTENT_MORE_POPUP;
		});

		return {
			t,
			storeManager,
			POPUP_MODE,
			isContentsOptionModal,
			isContentsSelectModal,
			isLangSelectModal,
			isFollowModal,
			isMobileMenuPopup,
			isCopyrightPopup,
			isEditChannelModal,
			isReportPopup,
			isMakePopup,
			isContentsCopyPopup,
			adminPopupList,
			adminPopupOpenFlag,
			isContentsRenamePopup,
			isInspectionPopup,
			isContentsPopup,
			isContentReportPopup,
			isContentsMoreModal,
			onCloseAdminPopup,
			closePopup,
		};
	},
});
</script>
<template>
	<div v-if="storeManager.stateStore.popupMode !== POPUP_MODE.NONE" class="popup-bg mobile-menu" @click.self="closePopup">
		<apoc-link v-if="isMobileMenuPopup" class="close-button" href="#" @click="closePopup">
			<i class="fa fa-regular fa-xmark" style="font-size: 26px" />
		</apoc-link>
		<Transition name="wfade-mslide"> <contents-option-modal v-if="isContentsOptionModal"></contents-option-modal> </Transition
		><Transition name="wfade-mslide">
			<follow-modal v-if="isFollowModal"></follow-modal>
		</Transition>
		<Transition name="wfade-mslide">
			<lang-select-modal v-if="isLangSelectModal"></lang-select-modal>
		</Transition>
		<Transition name="wfade-mslide">
			<contents-select-modal v-if="isContentsSelectModal"></contents-select-modal>
		</Transition>
		<Transition name="wfade-mslide">
			<contents-more-modal v-if="isContentsMoreModal"></contents-more-modal>
		</Transition>
		<Transition name="slide-left">
			<mobile-menu-popup v-if="isMobileMenuPopup"></mobile-menu-popup>
		</Transition>
		<!--		web : fade in out, mobile : slide up-->
		<Transition name="wfade-mslide">
			<copyright-popup v-if="isCopyrightPopup"></copyright-popup>
		</Transition>
		<Transition name="wfade-mslide">
			<edit-channel-modal v-if="isEditChannelModal"></edit-channel-modal>
		</Transition>
		<Transition name="wfade-mslide">
			<report-popup v-if="isReportPopup"></report-popup>
		</Transition>
		<Transition name="wfade-mslide">
			<make-popup v-if="isMakePopup"></make-popup>
		</Transition>
		<Transition name="wfade-mslide">
			<contents-copy-popup v-if="isContentsCopyPopup"></contents-copy-popup>
		</Transition>
		<Transition name="wfade-mslide">
			<content-rename-popup v-if="isContentsRenamePopup"></content-rename-popup>
		</Transition>
		<Transition name="wfade-mslide">
			<inspection-popup v-if="isInspectionPopup"></inspection-popup>
		</Transition>
		<Transition name="wfade-mslide">
			<contents-popup v-if="isContentsPopup"></contents-popup>
		</Transition>
		<Transition name="wfade-mslide">
			<content-report-popup v-if="isContentReportPopup"></content-report-popup>
		</Transition>
	</div>
	<div v-if="adminPopupOpenFlag && adminPopupList.length > 0" class="popup-bg mobile-menu" @click.self.stop.prevent="onCloseAdminPopup">
		<template v-for="item in adminPopupList" :key="item.seq">
			<modal :on-close-popup="onCloseAdminPopup" :seq="item.seq" :title="t(`admin.${item.popupCategory}`)">
				<div class="ql-editor" v-html="item.body"></div>
			</modal>
		</template>
	</div>
</template>
