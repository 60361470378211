import { CATEGORY_TYPE_CODE, STATE_YN } from '../../types';

// 카테고리
export class CategoryEntity {
	// 카테고리고유번호
	categoryIdx = '';
	// 카테고리명**
	categoryName = '';
	// 카테고리영문명
	categoryEnName = '';
	// 카테고리코드**
	categoryCode = '';
	// 카테고리유형코드**
	categoryTypeCode: CATEGORY_TYPE_CODE | undefined = undefined;
	// 카테고리설명
	categoryDesc = '';
	// 등록자회원고유번호
	regrUserIdx = '';
	// 등록일시
	regDt: Date | null = null;
	// 수정자회원고유번호
	modrUserIdx = '';
	// 수정일시
	modDt: Date | null = null;
	// 삭제여부
	delYn: STATE_YN = STATE_YN.N;
	shortUrl: string | undefined;
}

// 카테고리 연결 내역
export class CategoryConnectRecEntity extends CategoryEntity {
	// 카테고리연결일련번호
	categoryConnectSeq = 0;
	// 카테고리고유번호**
	categoryIdx = '';
	// 대상고유번호**
	targetIdx = '';
	// 공개여부**
	openYn: STATE_YN = STATE_YN.N;
	// 등록자회원고유번호
	regrUserIdx = '';
	// 등록일시
	regDt: Date | null = null;
	// 수정자회원고유번호
	modrUserIdx = '';
	// 수정일시
	modDt: Date | null = null;
	// 삭제여부
	delYn: STATE_YN = STATE_YN.N;
}
