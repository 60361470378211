<script lang="ts">
import { getCategoryList } from '@/api/category';
import { SearchCategoryListDto } from '@/api/dto/category.dto';
import ApocButton from '@/components/common/ApocButton.vue';
import ApocImageSet from '@/components/common/ApocImageSet.vue';
import ApocInput from '@/components/common/ApocInput.vue';
import ContentsOptionSelect from '@/components/common/ContentsOptionSelect.vue';
import AppConfig from '@/constants';
import { initStore } from '@/stores/store-manager';
import { LOADING_TYPE, LOCALE, PARENT_CATEGORY_TYPE_CODE } from '@/types';
import { getApiClient } from '@/utils/apiClient';
import { loadLocalData } from '@/utils/common-util';
import type { PropType } from 'vue';
import { computed, defineComponent, onMounted, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute, useRouter } from 'vue-router';
import { loadApiGauge } from '@/utils/utils';

// 검색창 (검색 옵션 드롭다운 메뉴 + 입력창 + 검색 버튼)
export default defineComponent({
	name: 'ApocSearch',
	components: { ApocImageSet, ApocInput, ApocButton, ContentsOptionSelect },
	props: {
		placeholder: {
			type: String as PropType<string>,
			default: '',
		},
		onEnter: {
			type: Function as PropType<(result: string) => void>,
			required: false,
			default: () => ({}),
		},
		modelValue: {
			type: String as PropType<string>,
		},
		readOnly: {
			type: Boolean as PropType<boolean>,
			required: false,
			default: false,
		},
	},
	setup(props, { emit }) {
		const { t } = useI18n({ useScope: 'global' });
		const storeManager = initStore();
		const router = useRouter();
		const route = useRoute();
		const keyword = ref<string>(route.query.keyword ? (route.query.keyword as string) : '');
		const hasInput = computed(() => keyword.value.length > 0);
		const categorySortOptions = ref<any>([]);
		const apiClient = getApiClient(AppConfig.API_SERVER, storeManager);
		const lang = ref<string | null>(null);

		// 헤더의 검색창에 입력 후 엔터 클릭 시 무조건 search 페이지로 라우트 이동
		const handleEnter = (value: string) => {
			router.push({ path: '/search', query: { keyword: keyword.value, page: 1 } });
		};

		/**
		 * 카테고리 변경
		 * @param v
		 */
		const getCategoryTypeCode = () => {
			const param = new SearchCategoryListDto();
			param.parentCategoryTypeCode = PARENT_CATEGORY_TYPE_CODE.APC;
			storeManager.dataStore.addPageApiTotalCount(1);
			getCategoryList(apiClient, param)
				.then(res => {
					storeManager.dataStore.addLoadedCount(1);
					// console.log('getCategoryList: ', storeManager.dataStore.pageApiTotalCount, storeManager.dataStore.loadedCount);
					loadApiGauge(LOADING_TYPE.ROLL, '', '');
					if (res.resultCode !== 0) {
						window.alert(t('msg.' + res.resultMsg));
					} else {
						if (res.data) {
							categorySortOptions.value.push({
								value: '',
								label: t('common.categoryType.total'),
								func: () => {
									storeManager.dataStore.setCategoryCode('');
								},
							});
							res.data.map(v => {
								if (lang.value === LOCALE.KO) {
									categorySortOptions.value.push({
										value: v.categoryCode,
										label: v.categoryName,
										func: () => {
											storeManager.dataStore.setCategoryCode(v.categoryCode);
										},
									});
								} else {
									categorySortOptions.value.push({
										value: v.categoryCode,
										label: v.categoryEnName,
										func: () => {
											storeManager.dataStore.setCategoryCode(v.categoryCode);
										},
									});
								}
							});
						}
					}
				})
				.catch(e => {
					storeManager.dataStore.addLoadedCount(1);
					// console.error('getCategoryList: ', storeManager.dataStore.pageApiTotalCount, storeManager.dataStore.loadedCount);
					loadApiGauge(LOADING_TYPE.ROLL, '', '');
					console.error(e);
				});
		};

		const clearInput = () => {
			keyword.value = '';
		};

		onMounted(() => {
			lang.value = loadLocalData(AppConfig.KEYS.CONST.CHANGE_LANG);
			getCategoryTypeCode();
		});

		watch(
			() => keyword.value,
			() => {
				storeManager.dataStore.setSearchKeyword(keyword.value);
			},
		);

		return {
			t,
			keyword,
			hasInput,
			// compSearchKeyword,
			categorySortOptions,
			handleEnter,
			clearInput,
		};
	},
});
</script>

<template>
	<div class="apoc-search">
		<contents-option-select
			class="apoc-search-select"
			:option-list="categorySortOptions"
			:placeholder="t('common.category')"
			:is-check="false"
			:except-placeholder-from-list="true"></contents-option-select>
		<apoc-input
			class="apoc-search-input"
			:placeholder="placeholder"
			:on-enter="handleEnter"
			:autofocus="true"
			:model-value="keyword"
			@update:model-value="keyword = $event" />
		<i v-if="hasInput" class="fa fa-solid fa-x search-x-btn" @click="clearInput" />
		<div class="apoc-search-btn">
			<apoc-button><apoc-image-set src="/assets/images/common/icons/search.svg" @click="handleEnter" /></apoc-button>
		</div>
	</div>
</template>

<style scoped></style>
