<script lang="ts">
import { defineComponent, onMounted, onUnmounted, ref, watch } from 'vue';
import { ILoadingInterface, LOADING_TYPE } from '@/types/index';
import loadingTypeBounce from './LoadingTypeBounce.vue';
import loadingTypeRoll from './LoadingTypeRoll.vue';
import loadingTypeSlide from './LoadingTypeSlide.vue';
import { getEmitter } from '@/utils/event-emitter-utils';

export default defineComponent({
	name: 'LoadingManager',
	components: {
		loadingTypeBounce,
		loadingTypeRoll,
		loadingTypeSlide,
	},

	setup() {
		const loadingType = ref<LOADING_TYPE | undefined>(LOADING_TYPE.NONE);
		const title = ref('');
		const des = ref('');
		const progress = ref<number>(0);

		// 퍼센트 차오르는 테스트용 인터벌입니다.
		// const intervalId = setInterval(() => {
		// 	progress.value += 10;

		// 	if (progress.value >= 100) {
		// 		clearInterval(intervalId);
		// 	}
		// }, 500);

		//progress 값 100 달성시 0.5초뒤 NONE 처리
		const handleProgress = (state: number) => {
			progress.value = state;
			if (progress.value >= 100) {
				setTimeout(() => {
					loadingType.value = LOADING_TYPE.NONE;
				}, 500);
			}
		};

		const getLoadingData = (data: ILoadingInterface) => {
			loadingType.value = data.loadingType;
			title.value = data.title;
			des.value = data.des;
			progress.value = data.progress;
		};

		//handleProgress 조작
		watch(progress, newValue => {
			if (newValue === 100) {
				handleProgress(newValue);
			}
		});
		onMounted(() => {
			getEmitter().on('LOADING_STATE', getLoadingData);
		});
		onUnmounted(() => {
			getEmitter().off('LOADING_STATE', getLoadingData);
		});

		return {
			LOADING_TYPE,
			loadingType,
			title,
			progress,
			des,
		};
	},
});
</script>
<template>
	<div v-if="loadingType !== LOADING_TYPE.NONE" class="loading-bg">
		<!-- des 사용하는 디자인이 아직 없으므로 전달하지 않음 -->
		<loadingTypeBounce v-if="loadingType === LOADING_TYPE.BOUNCE" :title="title" :progress="progress" />
		<loadingTypeRoll v-if="loadingType === LOADING_TYPE.ROLL" :title="title" :progress="progress" />
		<loadingTypeSlide v-if="loadingType === LOADING_TYPE.SLIDE" :title="title" :progress="progress" />
	</div>
</template>

<style lang=""></style>
