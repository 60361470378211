import AppConfig from '@/constants';
import { THIRD_PARTY_TYPE } from '@/stores/common-store';
import { loadSessionData } from '@/utils/common-util';
import { getMsTeams, TAB_TYPE } from '@/utils/microsoft-teams';
import { StoreManager } from '@/stores/store-manager';

export interface goAuthorContextInterface {
	authorUrl: string;
	target?: string;
	ctx?: string;
	callback?: () => void;
}

// const storeManager = initStore();

/**
 * 저작도구 넘어가기
 * @param authorUrl 저작도구 연결될 url
 * @param context 넘길 파라미터
 */
export const goAuthor = async (context: goAuthorContextInterface, storeManager: StoreManager) => {
	if (storeManager.commonStore.thirdPartyType === THIRD_PARTY_TYPE.MS_TEAMS) {
		await getMsTeams().navigateToTap(TAB_TYPE.AUTHOR, context?.ctx);
	} else if (storeManager.commonStore.thirdPartyType === THIRD_PARTY_TYPE.CAFE_24) {
		const cafe24MallId = loadSessionData(AppConfig.KEYS.CONST.CAFE24.MALL_ID);
		window.open(context.authorUrl + (cafe24MallId ? `&cafe24MallId=${cafe24MallId}` : ''), context?.target);
	} else {
		window.open(context.authorUrl, context?.target);
	}
	if (context?.callback) context?.callback();
};
