/**
 * response code
 */
export const RESULT_CODE = {
	SUCCESS: { code: 0, msg: 'RESULT_SUCCESS' },
	FAILED: { code: 99, msg: 'RESULT_FAILED' },
	USER_NOT_FOUND: { code: 98, msg: 'RESULT_USER_NOT_FOUND' },
	DUPLICATED: { code: 97, msg: 'RESULT_DUPLICATED' },
	INVALID_PARAMETER: { code: 96, msg: 'RESULT_INVALID_PARAM' },
	PASSWORD_NOT_MATCHED: { code: 95, msg: 'RESULT_PASSWORD_NOT_MATCHED' },
	PASSWORD_INCORRECT: { code: 94, msg: 'RESULT_PASSWORD_INCORRECT' },
	NO_PERMISSION: { code: 91, msg: 'RESULT_NO_PERMISSION' },
	NEED_MORE_INFO: { code: 90, msg: 'NEED_MORE_INFO' },
	USER_EXIST: { code: 89, msg: 'USER_ALREADY_EXIST' },
	DB_ERROR: { code: 88, msg: 'RESULT_DB_ERROR' },
	AWS_ERROR: { code: 87, msg: 'RESULT_AWS_ERROR' },
};
/**
 * Result code interface
 */
export interface IResultCode {
	code: number;
	msg: string;
}

export interface ILoadingInterface {
	title: string;
	loadingType: LOADING_TYPE;
	des: string;
	progress: number;
}

/**
 * 사용자 성별
 */
export enum USER_GENDER {
	MALE = 'M',
	FEMALE = 'F',
}

/**
 * 사용자 구분
 */
export enum USER_TYPE {
	ADMIN = 'ADMIN',
	USER = 'USER',
	CREATOR = 'CREATOR',
	NONMEMBER = 'NONMEMBER',
}

/**
 * 사용자 로그인 구분
 */
export enum LOGIN_TYPE {
	APOC = 'APOC',
	QR = 'QR',
	GOOGLE = 'GOOGLE',
	FACEBOOK = 'FACEBOOK',
	KAKAO = 'KAKAO',
	MICROSOFT = 'MICROSOFT',
}

/**
 * YN 상태값
 */
export enum STATE_YN {
	Y = 'Y',
	N = 'N',
}

/**
 * 카테고리 대분류
 */
export enum CATEGORY_TYPE_CODE {
	PRODUCT = 'PRODUCT', // 제품
	ASSET = 'ASSET', // 에셋
	CONTENT = 'CONTENT', // 콘텐트
}

/**
 * 저작도구 카테고리 내 탭
 */
export enum CATEGORY_TAB {
	STKALL = 'STKALL', // 스티커 전체
	HOT = 'HOT', // 인기있는
}

/**
 * 즐겨찾기 유형 코드
 */
export enum FAV_TYPE_CODE {
	PRODUCT = 'PRODUCT', // 제품
	ASSET = 'ASSET', // 에셋
	CONTENT = 'CONTENT', // 콘텐트
}

/**
 * 파일 타입명
 * common_code
 * code_type_code = 'file_type_code'
 */
export enum FILE_TYPE {
	BANNER_IMG = 'BANNER_IMG', // 배너이미지
	BOARD_CONTENTS_IMG = 'BOARD_CONTENTS_IMG', // 게시판 콘텐츠 이미지
	BOARD_MAIN_IMG = 'BOARD_MAIN_IMG', // 게시판 메인 이미지
	CATEGORY_IMG = 'CATEGORY_IMG', // 카테고리 썸네일
	CONTENT_MAIN_IMG = 'CONTENT_MAIN_IMG', // 콘텐츠 썸네일
	CONTENT_PRV_IMG = 'CONTENT_PRV_IMG', // 콘텐츠 미리보기 썸네일
	DIRECT_FILE = 'DIRECT_FILE', // 사용자 직업 업로드 요소 파일
	ELEMENT_FILE = 'ELEMENT_FILE', // 요소 파일
	ELEMENT_IMG = 'ELEMENT_IMG', // 요소 썸네일
	ELEMENT_IMG_EN = 'ELEMENT_IMG_EN', // 요소 썸네일 영문
	PRODUCT_MODEL = 'PRODUCT_MODEL', // 제품 모델
	PRODUCT_PREVIEW = 'PRODUCT_PREVIEW', // 제품 미리보기 썸네일
	USER_PROFILE_IMG = 'USER_PROFILE_IMG', // 사용자 프로필 이미지
	YOUTUBE_THUMBS_IMG = 'YOUTUBE_THUMBS_IMG', // 유튜브 썸네일 이미지
}

/**
 * 파일 확장자
 */
export enum EXT_CODE {
	GLB = '.glb',
	GLTF = '.gltf',
	WEBP = '.webp',
	PNG = '.png',
	JPG = '.jpg',
	JPEG = '.jpeg',
	GIF = '.gif',
	MP3 = '.mp3',
	WAV = '.wav',
	MP4 = '.mp4',
	MOV = '.mov',
}

export enum myContents_type {
	CHANNEL = 'channel',
	CONTENTS = 'contents',
}

/**
 * 어셋 종류
 * common_code
 * code_type_code = 'asset_type_code'
 */
export enum ELEMENT_TYPE {
	BUTTON = 'BUTTON',
	COLOR = 'COLOR',
	IMAGE = 'IMAGE',
	MODEL = 'MODEL',
	MUSIC = 'MUSIC',
	OBJECT = 'OBJECT',
	SHAPE = 'SHAPE',
	STICKER = 'STICKER',
	TEXT = 'TEXT',
	VIDEO = 'VIDEO',
	YOUTUBE = 'YOUTUBE',
	SPRITE = 'SPRITE', //gif
}

/**
 * 콘텐트와 관련된 유저 유형 코드
 * 소유자, 채널 포함된 자, 협업 참여자
 */
export enum AUTHOR_USER_TYPE_CODE {
	CREATOR = 'CREATOR', // 콘텐트 제작자
	COLLABO = 'COLLABO', // 협업 참여자
	CHANNEL_CREATOR = 'CHANNEL_CREATOR', // 채널 소유자
	CHANNEL = 'CHANNEL', // 채널 참여자
}

/**
 * 로그인 상태 코드
 */
export enum LOGIN_LOGOUT_CODE {
	LOGIN = 'LOGIN',
	LOGOUT = 'LOGOUT',
}

/**
 * 트랜잭션유형코드
 */
export enum TRANSACTION_TYPE_CODE {
	GET = 'GET',
	INSERT = 'INSERT',
	UPDATE = 'UPDATE',
	DELETE = 'DELETE',
}

/**
 * 로그 타입 코드
 * common_code
 * code_type_code = 'log_type_code'
 */
export enum LOG_TYPE_CODE {
	USER_REG = 'USER_REG', // 사용자 등록
	USER_MOD = 'USER_MOD', // 사용자 수정
	USER_LOGIN = 'USER_LOGIN', // 사용자 로그인
	BOARD = 'BOARD', // 게시판
	BOARD_COMMENT = 'BOARD_COMMENT', // 게시판 댓글
	BOARD_EVAL = 'BOARD_EVAL', // 게시판 평가
	CART = 'CART', // 장바구니
	REFUND = 'REFUND', // 환불
	DISCOUNT = 'DISCOUNT', // 할인
	COUPON = 'COUPON', // 쿠폰
	COUPON_APPLY_TARGET = 'COUPON_APPLY_TARGET', // 쿠폰 적용대상
	COUPON_ISSUE = 'COUPON_ISSUE', // 쿠폰 발행
	PAYMENT = 'PAYMENT', // 결제
	PAYMENT_HIS = 'PAYMENT_HIS', // 결제 이력
	PRODUCT = 'PRODUCT', // 상품
	PRODUCT_PACKAGE = 'PRODUCT_PACKAGE', // 상품패키지
	PRODUCT_DETAIL = 'PRODUCT_DETAIL', // 상품상세
	EMAIL_SENDER = 'EMAIL_SENDER', // 이메일 발송자
	EMAIL_QUEUE = 'EMAIL_QUEUE', // 이메일 큐
	EMAIL_TARGET = 'EMAIL_TARGET', // 이메일 대상
	EMAIL_TARGET_RECIPIENT = 'EMAIL_TARGET_RECIPIENT', // 이메일 대상 수신자
	ANALYSIS_INFO = 'ANALYSIS_INFO', //분석정보
	ANALYSIS_ITEM = 'ANALYSIS_ITEM', //분석항목
	ANALYSIS_INFO_ITEM = 'ANALYSIS_INFO_ITEM', //분석정보항목
	ANALYSIS_INFO_COLLECTION = 'ANALYSIS_INFO_COLLECTION', //분석정보수집
	DIRECT_UPLOAD = 'DIRECT_UPLOAD', // 직접업로드
	DIRECT_YOUTUBE = 'DIRECT_YOUTUBE', // 직접 유투브 업로드
	ASSET = 'ASSET', // 어셋
	ASSET_META = 'ASSET_META', // 어셋 메타
	ASSET_CPR = 'ASSET_CPR', // 어셋 저작권
	ASSET_CPR_META = 'ASSET_CPR_META', // 어셋 저작권 메타
	CATEGORY = 'CATEGORY', // 카테고리
	CATEGORY_CONNECT_REC = 'CATEGORY_CONNECT_REC', // 카테고리 연결내역
	CHANNEL = 'CHANNEL', // 채널
	CHANNEL_PARTNER = 'CHANNEL_PARTNER', // 채널 참여자
	CHANNEL_CONTENT_REC = 'CHANNEL_CONTENT_REC', //채널 컨텐트 내역
	CHAT_ROOM = 'CHAT_ROOM', //채팅방
	CHAT_ROOM_USER = 'CHAT_ROOM_USER', //채팅방 회원
	CHAT_MESSAGE = 'CHAT_MESSAGE', // 채팅 메시지
	COLLABO_ROOM = 'COLLABO_ROOM', // 협업방
	COLLABO_ROOM_USER = 'COLLABO_ROOM_USER', // 협업방 회원
	COMMON_CODE = 'COMMON_CODE', // 공통 코드
	ATTACH_FILE = 'ATTACH_FILE', // 첨부파일
	DEFECT = 'DEFECT', // 신고
	FAV = 'FAV', // 즐겨찾기
	POPUP = 'POPOP', // 팝업
	BANNER = 'BANNER', // 배너
	CONTENT = 'CONTENT', // 콘텐트
	CONTENT_COPY = 'CONTENT_COPY', // 콘텐트 복사
	CONTENT_BODY = 'CONTENT_BODY', // 콘텐트 바디
	CONTENT_COLLABO_REC = 'CONTENT_COLLABO_REC', // 콘텐트 협업 내역
	CONTENT_COMMENT = 'CONTENT_COMMENT', // 콘텐트 댓글
	CONTENT_EVAL = 'CONTENT_EVAL', // 콘텐트 평가
	CONTENT_PLAY_REC = 'CONTENT_PLAY_REC', // 콘텐트 플레이 내역
	CONTENT_PRICE = 'CONTENT_PRICE', // 콘텐트 금액
	CONTENT_TAG_REC = 'CONTENT_TAG_REC', // 콘텐트 태그 내역
	USER_NOTICE = 'USER_NOTICE', // 사용자 알림 설정 추가
	USER_FOLLOWER_NOTICE = 'USER_FOLLOWER_NOTICE', // 사용자 친구 알림 설정
	NOTICE_QUEUE = 'NOTICE_QUEUE', // 알림큐
}

/**
 * 서버 운영 환경 구분
 */
export enum ServerEnv {
	DEV = 'DEV',
	PROD = 'PRODUCTION',
}

/**
 * 배너 URL 연결 유형 코드
 * common_code
 * code_type_code = 'connect_type_code'
 * */
export enum CONNECT_TYPE {
	BANNER = 'BANNER',
	CONTENT = 'CONTENT',
	OUTLINK = 'OUTLINK',
}

/**
 * 배너 유형 코드
 * common_code
 * code_type_code = 'banner_type_code'
 */
export enum BANNER_TYPE {
	CONTENTS_MINI1_EN_MB = 'CONTENTS_MINI1_EN_MB',
	CONTENTS_MINI1_EN_PC = 'CONTENTS_MINI1_EN_PC',
	CONTENTS_MINI1_EN_TB = 'CONTENTS_MINI1_EN_TB',
	CONTENTS_MINI1_KO_MB = 'CONTENTS_MINI1_KO_MB',
	CONTENTS_MINI1_KO_PC = 'CONTENTS_MINI1_KO_PC',
	CONTENTS_MINI1_KO_TB = 'CONTENTS_MINI1_KO_TB',
	CONTENTS_PC_TOP = 'CONTENTS_PC_TOP',
	CONTENTS_TOP = 'CONTENTS_TOP',
	MAIN_MINI1_EN_MB = 'MAIN_MINI1_EN_MB',
	MAIN_MINI1_EN_PC = 'MAIN_MINI1_EN_PC',
	MAIN_MINI1_EN_TB = 'MAIN_MINI1_EN_TB',
	MAIN_MINI1_KO_MB = 'MAIN_MINI1_KO_MB',
	MAIN_MINI1_KO_PC = 'MAIN_MINI1_KO_PC',
	MAIN_MINI1_KO_TB = 'MAIN_MINI1_KO_TB',
	MAIN_MINI2_EN_MB = 'MAIN_MINI2_EN_MB',
	MAIN_MINI2_EN_PC = 'MAIN_MINI2_EN_PC',
	MAIN_MINI2_EN_TB = 'MAIN_MINI2_EN_TB',
	MAIN_MINI2_KO_MB = 'MAIN_MINI2_KO_MB',
	MAIN_MINI2_KO_PC = 'MAIN_MINI2_KO_PC',
	MAIN_MINI2_KO_TB = 'MAIN_MINI2_KO_TB',
	MAIN_TOP_EN = 'MAIN_TOP_EN',
	MAIN_TOP_KO = 'MAIN_TOP_KO',
	STUDIO_MINI1_EN_MB = 'STUDIO_MINI1_EN_MB',
	STUDIO_MINI1_EN_PC = 'STUDIO_MINI1_EN_PC',
	STUDIO_MINI1_EN_TB = 'STUDIO_MINI1_EN_TB',
	STUDIO_MINI1_KO_MB = 'STUDIO_MINI1_KO_MB',
	STUDIO_MINI1_KO_PC = 'STUDIO_MINI1_KO_PC',
	STUDIO_MINI1_KO_TB = 'STUDIO_MINI1_KO_TB',
	STUDIO_TOP_EN = 'STUDIO_TOP_EN',
	STUDIO_TOP_KO = 'STUDIO_TOP_KO',
	TEMPLATE_TOP_EN = 'TEMPLATE_TOP_EN',
	TEMPLATE_TOP_KO = 'TEMPLATE_TOP_KO',
}

export enum DATE_FORMAT {
	YYYYMMDD = 'YYYY-MM-DD',
}

/**
 * 콘텐츠 공개 유형
 */
export enum OPEN_TYPE_CODE {
	PUBLIC = 'PUBLIC', //공개
	PRIVATE = 'PRIVATE', //비공개
	DIRECT = 'DIRECT', //일부 공개
}

/**
 * 콘텐츠 카테고리 유형
 */
export enum CONTENTS_CATEGORY_TYPE_CODE {
	SNS = 'SNS', //SNS
	MINI_GAME = 'MINI_GAME', //미니게임
	TYPE_TEST = 'TYPE_TEST', //유형테스트
	EVENT_BANNER = 'EVENT_BANNER', // 이벤트/배너
	CARD_NEWS = 'CARD_NEWS', //카드뉴스
	PRESENTATION = 'PRESENTATION', //프레젠테이션
	SCHOOL_EDU = 'SCHOOL_EDU', //학교/교육
}

/**
 * 제작 유형
 */
export enum PRODUCE_TYPE_CODE {
	APOC = 'APOC', //APOC Studio
	ETC = 'ETC', //APOC 외
}

/**
 * 게시판 유형 코드
 * common_code
 * code_type_code = 'board_type_code'
 */
export enum BOARD_TYPE_CODE {
	UPDATE = 'UPDATE',
	NOTICE = 'NOTICE',
	NEWS = 'NEWS',
	FAMPPY = 'FAMPPY',
	'2D' = '2D',
	'3D' = '3D',
	EDUCATION = 'EDUCATION',
	MARKETING = 'MARKETING',
	BUG = 'BUG',
	FUNCTION = 'FUNCTION',
	QNA = 'QNA',
	FORUM = 'FORUM',
	TUTORIAL = 'TUTORIAL',
	SERVICE = 'SERVICE',
}

/**
 * 콘텐트 평가 유형 코드
 */
export enum CONTENT_EVAL_LIKEBAD_CODE {
	BAD = 'BAD',
	LIKE = 'LIKE',
}

/**
 * 컨텐트평가유형코드
 */
export enum CONTENT_EVAL_TYPE_CODE {
	LIKEBAD = 'LIKEBAD',
	STAR = 'STAR',
	LIKE = 'LIKE',
}

/**
 * 게시판 평가 유형 코드
 * common_code
 * code_type_code = 'board_eval_type_code'
 */
export enum BOARD_EVAL_TYPE_CODE {
	LIKEBAD = 'LIKEBAD', // 좋아요싫어요
	LIKE = 'LIKE', // 좋아요
}

/**
 * 게시판 평가 좋아요 싫어요 코드
 * common_code
 * code_type_code = 'board_eval_likebad_code'
 */
export enum BOARD_EVAL_LIKEBAD_CODE {
	LIKE = 'LIKE', // 좋아요
	BAD = 'BAD', // 싫어요
}

/**
 * 게시판 평가 좋아요 코드
 * common_code
 * code_type_code = 'board_eval_like_code'
 */
export enum BOARD_EVAL_LIKE_CODE {
	LIKE = 'LIKE', // 좋아요
}

/**
 * 신고 구분
 */
export enum DEFECT_TYPE_CODE {
	CONTENT = 'CONTENT',
	ASSET = 'ASSET',
	PRODUCT = 'PRODUCT',
	BOARD = 'BOARD',
	BOARD_COMMENT = 'BOARD_COMMENT',
	CONTENT_COMMENT = 'CONTENT_COMMENT',
}

/**
 * 신고 처리 상태
 */
export enum DEFECT_PROCESS_STATE_CODE {
	SUBMIT = 'SUBMIT', //신청
	COMPLETE = 'COMPLETE', //처리 완료
}

/**
 * 정렬 유형 코드
 */
export enum ORDER_TYPE {
	READ_COUNT_DESC = 'READ_COUNT_DESC', // 읽음 수가 많은 오름차순
	LIKE_COUNT_DESC = 'LIKE_COUNT_DESC', // 좋아요 수가 많은 오름차순
	COMMENT_COUNT_DESC = 'COMMENT_COUNT_DESC', // 댓글 수가 많은 오름차순
	NAME_DESC = 'NAME_DESC', // 이름 오름차순
	NAME_ASC = 'NAME_ASC', // 이름 내림차순
	NEW = 'NEW', // 가장 최신
	OLD = 'OLD', // 가장 오래된
	HOT = 'HOT', // 3일 이내 가장 많이 본 순
	PLAY = 'PLAY', // 플레이순
	THIS_WEEK = 'THIS_WEEK', // 이번주 이내 가장 많이 본 순
	POPULAR = 'POPULAR', // 전체 가장 많이 본 순
	RANDOM_A = 'RANDOM_A', // 랜덤 고유번호 오름차순
	RANDOM_B = 'RANDOM_B', // 랜덤 등록일자 오름차순
	RANDOM_C = 'RANDOM_C', // 랜덤 영문명 오름차순
	RANDOM_D = 'RANDOM_D', // 랜덤 고유번호 내림차순
	RANDOM_E = 'RANDOM_E', // 랜덤 등록일자 내림차순
	RANDOM_F = 'RANDOM_F', // 랜덤 이름 내림차순
	GANADA = 'GANADA', // 가나다
	UPDATE = 'UPDATE', // 업데이트
	LIKE = 'LIKE', // 좋아요
	NOW_YEAR = 'NOW_YEAR', // 올해
	REPLY = 'REPLY', // 댓글 많은 순
}

/**
 * 사용자 팔로워 알림 설정 코드
 */
export enum USER_FOLLOWER_NOTICE_TYPE_CODE {
	ON = 'ON',
	OFF = 'OFF',
}

/**
 * 사용자 등급 유형 코드
 */
export enum USER_GRADE_TYPE_CODE {
	PAYMENT = 'PAYMENT',
	USER = 'USER',
	PERIOD = 'PERIOD',
}

/**
 * 사용자 등급 유형
 */
export enum GRADE_USER_CODE {
	CREATOR = 'CREATOR',
	USER = 'USER',
	ADMIN = 'ADMIN',
	NONMEMBER = 'NONMEMBER',
}

/**
 * 사용자 결제 등급
 */
export enum GRADE_PAYMENT_CODE {
	GRADE1 = 'GRADE1',
	GRADE2 = 'GRADE2',
}

/**
 * 사용자 기간 등급
 */
export enum GRADE_PERIOD_CODE {
	GRADE1 = 'GRADE1',
}

/**
 * 에셋 상태 코드
 */
export enum ASSET_STATE_CODE {
	PRIVATE = 'PRIVATE',
	PUBLIC = 'PUBLIC',
}

/**
 * 유입경로 코드
 */
export enum INFLOW_PATH_CODE {
	GONGU = 'GONGU',
	APOC = 'APOC',
}

/**
 * 저작권 유형
 */
export enum LICENSE_CODE {
	CC_BY = 'CC BY', // 공유 — 복제, 배포, 전시, 공연 및 공중송신 (포맷 변경도 포함), 변경 — 리믹스, 변형, 2차적 저작물의 작성 영리목적으로도 이용이 가능합니다.
	CC_BY_ND = 'CC BY-ND', // 공유 — 복제, 배포, 전시, 공연 및 공중송신 (포맷 변경도 포함) 영리목적으로도 이용이 가능합니다.
	CC_BY_SA = 'CC BY-SA', // 공유 — 복제, 배포, 전시, 공연 및 공중송신 (포맷 변경도 포함), 변경 — 리믹스, 변형, 2차적 저작물의 작성 영리목적으로도 이용이 가능합니다.
	BY_NC_SA = 'BY-NC-SA', // 공유 — 복제, 배포, 전시, 공연 및 공중송신 (포맷 변경도 포함), 변경 — 리믹스, 변형, 2차적 저작물의 작성
	BY_NC_ND = 'BY-NC-ND', // 공유 — 복제, 배포, 전시, 공연 및 공중송신 (포맷 변경도 포함)
	MEAN_CC_BY = 'MEAN-CC-BY', // 저작자 표시
	MEAN_CC_BY_ND = 'MEAN-CC-BY-ND', // 저작자 표시 - 비영리
	MEAN_CC_BY_SA = 'MEAN-CC-BY-SA', // 저작자 표시 - 변경금지
	MEAN_BY_NC_SA = 'MEAN-BY-NC-SA', // 저작자 표시 - 동일조건변경허락
	MEAN_BY_NC_ND = 'MEAN-BY-NC-ND', // 저작자 표시 - 비영리 - 변경금지
	MEAN_FREE = 'FREE', // 자유 이용 가능
}

/**
 * 저작도구 인증시 유입경로
 */
export enum ACCESS_RIGHT {
	NEW = 'NEW', // 신규 콘텐트
	CRACK = 'CRACK', //수정 콘텐트
	CHANNEL = 'CHANNEL', // 채널 주인 콘텐트
	NONE = 'NONE', // 협업자 또는 채널 유저
}

export enum ChatUtilEvent {
	DISCONNECT = 'disconnect',
	CONTENT_DELETE = 'content.delete',
}

export enum ChatWorkerEvent {
	CONNECT = 'chat.connect',
	SEND_EVENT = 'chat.send.event',
}

/**
 * 화면에 노출되는 카테고리의 대분류 코트
 */
export enum PARENT_CATEGORY_TYPE_CODE {
	BDL = 'BDL', // 번들, 저작도구에 기본으로 노축되는 코드
	APC = 'APC', // 플래폼에 노출되는 코드
}

export interface SelectOptionInterface {
	value: string | number;
	label: string;
}

export interface StatusCodeInterface {
	[code: string]: number;
}

export interface ResponseDataInterface {
	resultCode: number;
	resultMsg: string;
	rows: number;
	totalCount: number;
	data?: any;
}

export interface BannerInterface {
	bannerIdx: string;
	bannerImgUrl: string;
	connectType: string;
	connectUrl: string;
	contents: string;
	modDt: string;
	regDt: string;
	stateUse: string;
	title: string;
	userId: string;
	userIdx: string;
	userName: string;
	userNicknm: string;
	viewOrder: number;
	bannerType: string;
}

export interface BannerResponseDataInterface extends ResponseDataInterface {
	data: BannerInterface[];
}

export interface BannerListResponseInterface {
	status: number;
	data: BannerResponseDataInterface;
}

export interface CrackTagInterface {
	tagName: string;
}

export interface UserInfoInterface {
	followerCount: string;
	followingCount: string;
	followerUser?: STATE_YN | null;
	alarmType?: USER_FOLLOWER_NOTICE_TYPE_CODE | null;
	tel: string;
	token: string;
	userId: string;
	userIdx: string;
	userName: string;
	userNicknm: string;
	userProfileImg: string;
	birth: string;
	gender: string;
	userType: string;
	loginType: string;
}

export interface CrackInterface {
	alarmType: string | null;
	contentBody: string;
	contentIdx: string;
	contentMainImg: string;
	contentName: string;
	crackPrvImgInfo: string | null;
	modDt: string | null;
	regDt: string;
	detailDesc: string;
	directFileList: string | null;
	endCanvas: string | null;
	followerCount: string;
	followingCount: string;
	followerUser: string | null;
	likeCount: string;
	likeContent: string | null;
	badContent: string | null;
	playCount: number;
	playDateReg: string;
	playTime: string;
	summaryDesc: string;
	startCanvas: string | null;
	stateExternal: string;
	stateProductReg: string;
	stateToddler: string;
	tagList: CrackTagInterface[];
	userIdx: string;
	userName: string;
	userNicknm: string;
	userProfileImg: string;
	userType: string;
	viewCount: number;
	favContent: string | null;
	category_name: string;
	category_name_en: string;
	category_idx: string;
	stateOpen: string;
}

export interface CrackDetailResponseDataInterface extends ResponseDataInterface {
	data: CrackInterface[];
}

export interface crackDetailResponseType {
	status: number;
	data: CrackDetailResponseDataInterface;
}

export interface ContentsInterface {
	assetImgInfo: string;
	assetName: string;
	assetType: string;
	categoryCode: string;
	categoryDesc: string;
	categoryIdx: string;
	categoryName: string;
	categoryNameEn: string;
	categoryInterface: string;
	modDt: string | null;
	regDt: string;
	elementNameEn: string | null;
	elementType: string | null;
	likeCount: string;
	metaList: string | null;
	playCount: number;
	stateOpen: string;
	stateProductReg: string;
	targetIdx: string;
	userIdx: string;
	userName: string;
	userNicknm: string;
	userProfileImg: string;
	contentMainImg: string;
	favContent: string | null;
}

export interface UserInfoInterface {
	followerCount: string;
	followingCount: string;
	followerUser?: STATE_YN | null;
	alarmType?: USER_FOLLOWER_NOTICE_TYPE_CODE | null;
	tel: string;
	token: string;
	userId: string;
	userIdx: string;
	userName: string;
	userNicknm: string;
	userProfileImg: string;
	birth: string;
	gender: string;
	userType: string;
	loginType: string;
}

export interface CategoryInterface {
	assetCount: string;
	categoryCode: string;
	categoryDesc: string;
	categoryIdx: string;
	categoryImgInfo: string | null;
	categoryName: string;
	categoryNameEn: string;
	categoryInterface: string;
	modDt: string | null;
	regDt: string | null;
	userIdx: string;
}

export interface FollowingInterface {
	alarmList: string | null;
	alarmType: string;
	modDt: string | null;
	regDt: string;
	date_query: string;
	isNew?: boolean;
	userIdx: string;
	userName: string;
	userNicknm: string;
	userProfileImg: string | null;
	userTel: string;
	targetIdx?: string;
}

export interface SaveCrackInterface {
	alarmType: string | null;
	badContent: string | null;
	contentIdx: string;
	contentMainImg: string;
	contentName: string;
	crackPrvImgInfo: string | null;
	modDt: string | null;
	regDt: string;
	detailDesc: string | null;
	directFileList: string | null;
	endCanvas: string | null;
	favContent: string;
	followerCount: string;
	followerUser: string | null;
	followingCount: string;
	likeCount: string;
	likeContent: string;
	playCount: number;
	playTime: string;
	summaryDesc: string;
	startCanvas: string | null;
	stateProductReg: string;
	stateToddler: string;
	tagList: string | null;
	userIdx: string;
	userName: string;
	userNicknm: string;
	userProfileImg: string;
	userType: string;
	viewCount: string;
}

export interface CrackPlayInterface {
	certCode: string;
	contentName: string;
	dateExpire: string;
	modDt: string | null;
	regDt: string | null;
	dateUse: string | null;
	stateUse: string;
	userIdx: string;
	userName: string;
	userNicknm: string;
	userProfileImg: string;
	userType: string;
	stateExternal: string | null | undefined;
	externalUrl: string | null | undefined;
}

export interface ChannelInterface {
	channelDesc: string;
	channelIdx: string;
	channelName: string;
	crackCount: string;
	regDt: string;
	modDt: string;
	userCount: string;
	userIdx: string;
	userName: string;
	userNicknm: string;
	channelColor: string;
	userProfileImg: string | null | undefined;
}

export interface BoardInterface {
	body: string;
	boardIdx: string;
	board_img_info: string | undefined;
	boardTypeCode: string;
	commentCount: string;
	modDt: string;
	regDt: string;
	likeBoard: string;
	likeCount: string;
	tableType: string;
	title: string;
	userId: string;
	userIdx: string;
	userNicknm: string;
	viewCount: string;
}

export interface CopyrightInterface {
	author: string;
	elementName: string;
	itemLicence: string;
	uciCode: string;
}

export interface CommunityCategory {
	title: string;
	boardTypeCode: BOARD_TYPE_CODE;
	iconClass: string;
}

/**
 * 어셋 종류
 * common_code
 * code_type_code = 'asset_type_code'
 */
export enum ASSET_TYPE_CODE {
	TEMPLATE = 'TEMPLATE',
	BUTTON = 'BUTTON',
	COLOR = 'COLOR',
	IMAGE = 'IMAGE',
	MODEL = 'MODEL',
	MUSIC = 'MUSIC',
	OBJECT = 'OBJECT',
	SHAPE = 'SHAPE',
	STICKER = 'STICKER',
	TEXT = 'TEXT',
	VIDEO = 'VIDEO',
	YOUTUBE = 'YOUTUBE',
	SPRITE = 'SPRITE', //gif
}

/**
 * 저작도구 인증시 유입경로
 */
export enum ACCESS_TYPE {
	NEW = 'NEW', // 신규 콘텐트
	CONTENT = 'CONTENT', //수정 콘텐트
	CHANNEL = 'CHANNEL', // 채널 주인 콘텐트
	NONE = 'NONE', // 협업자 또는 채널 유저
}

/**
 * 팔로워 유형 코드
 * common_code
 * code_type_code = 'follow_type_code'
 */
export enum FOLLOW_TYPE_CODE {
	FOLLOWER = 'FOLLOWER', // 팔로워
	FOLLOWING = 'FOLLOWING', // 팔로윙
}

/**
 * 회원 이력 코드
 * common_code
 * code_type_code = 'user_his_code'
 */
export enum USER_HIS_CODE {
	JOIN = 'JOIN', // 가입(login_type_code 변경)
	WITHDRAWAL = 'WITHDRAWAL', // 탈회
}

/**
 * 캔버스 사이즈 모드
 */
export enum CANVAS_MODE {
	HORIZON = 'horizon', //가로형
	VERTICAL = 'vertical', //세로형
	SQUARE = 'square', //정방형
}

/**
 * 채널/콘텐츠 사용자 권한 유형 코드
 * common_code
 * code_type_code = 'user_permission_type_code'
 */
export enum USER_PERMISSION_TYPE_CODE {
	CHANNEL_HOST = 'CHANNEL_HOST', // 채널 장
	CHANNEL_USER = 'CHANNEL_USER', // 채널 참여자
	CONTENT_HOST = 'CONTENT_HOST', // 콘텐츠 장
	CONTENT_USER = 'CONTENT_USER', // 콘텐츠 참여자
}

/**
 * 사용자 사용 언어 종류
 */
export enum LOCALE {
	KO = 'ko',
	EN = 'en',
}

/**
 * 단축 URL 유형코드
 */
export enum SHORT_TYPE_CODE {
	CONTENT = 'CONTENT',
	URL = 'URL',
}

/**
 * contents-area에 들어갈 타입
 */
export enum TEMPLATE_TYPE {
	LIST = 'LIST',
	NEW = 'NEW',
	EDIT = 'EDIT',
	DETAIL = 'DETAIL',
}

/**
 * sideBar subCategoryList에 사용되는 타입
 */
export interface subCategoryInterface {
	title: string;
	boardTypeCode: string;
	iconClass: string;
}

export enum SERVICE_PATH {
	POLICY = 'POLICY',
	TERMS = 'TERMS',
	COPYRIGHT = 'COPYRIGHT',
}

//콘텐츠 썸네일 이미지 타입
export interface contentMainImg {
	name: string;
	url: string;
}

/**
 * 기본 콘텐츠 이름
 */
export enum DEFAULT_CONTENT_NAME {
	KOR = '제목없음',
	ENG = 'Untitled',
}

/**
 * 리스트 아이템 보이기 개수 제한 (for. Flicking)
 */
export enum LIMIT_ITEM {
	PC = 4,
	TABLET = 3,
	MOBILE = 1,
}

/**
 *	로딩타입 종류
 *  */

export enum LOADING_TYPE {
	NONE = 0,
	TOTAL = 1,
	BOUNCE = 2,
	SLIDE = 3,
	ROLL = 4,
}

export enum INPUT_MAXLENGTH_TYPE {
	PROFILE = 10,
}

/**
 * 브라우저 종류 (체크해야하는 순서로 나열되어 있음)
 */
export enum BROWSER_TYPE {
	KAKAO = 'kakao',
	NAVER = 'naver',
	INSTA = 'instagram',
	WHALE = 'whale',
	SAMSUNG = 'samsungbrowser',
	EDGE = 'edge',
	QQ = 'qqbrowser',
	CHROME = 'chrome',
	FIREFOX = 'firefox',
	SAFARI = 'safari',
}

/**
 * 허용하지 않는 인앱 브라우저
 */
export const notAllowInappList: BROWSER_TYPE[] = [BROWSER_TYPE.KAKAO, BROWSER_TYPE.NAVER, BROWSER_TYPE.INSTA];

/**
 * 허용하는 브라우저 리스트
 */
export const allowBrowserList: BROWSER_TYPE[] = [
	BROWSER_TYPE.CHROME,
	BROWSER_TYPE.SAFARI,
	BROWSER_TYPE.FIREFOX,
	BROWSER_TYPE.SAMSUNG,
	BROWSER_TYPE.EDGE,
	BROWSER_TYPE.WHALE,
	BROWSER_TYPE.QQ,
];
