<script lang="ts">
import { initStore } from '@/stores/store-manager';
import type { PropType } from 'vue';
import { computed, defineComponent, ref, watch } from 'vue';
import { INPUT_MAXLENGTH_TYPE } from '@/types/index';

export default defineComponent({
	name: 'ApocInput',
	props: {
		placeholder: {
			type: String as PropType<string>,
			default: '',
		},
		type: {
			type: String as PropType<string>,
			default: 'text',
		},
		onEnter: {
			type: Function as PropType<(result: string) => void>,
			required: false,
			default: () => ({}),
		},
		modelValue: {
			type: String as PropType<string>,
		},
		handdleKeyUp: {
			type: Function as PropType<(v: string) => string>,
			required: false,
		},
		useKeyword: {
			type: Boolean as PropType<boolean>,
			required: false,
		},
		readOnly: {
			type: Boolean as PropType<boolean>,
			required: false,
			default: false,
		},
		autofocus: {
			type: Boolean as PropType<boolean>,
			required: false,
			default: false,
		},
		length: {
			type: Number,
			required: false,
			default: 200,
		},
	},
	setup(props, { emit }) {
		const storeManager = initStore();
		const inputValue = ref(props.modelValue || '');
		const compModelValue = computed(() => props.modelValue || '');

		const lengthValue = ref(props.length);

		const stopModelValue = watch(compModelValue, () => {
			inputValue.value = props.modelValue || '';
		});
		const handleKeyup = (e: KeyboardEvent): void => {
			if (e.code === 'Enter') {
				if (props.onEnter) props.onEnter(inputValue.value);
			}
			const value = e.target as HTMLInputElement;
			if (props.handdleKeyUp) {
				value.value = props.handdleKeyUp(value.value as string);
			} else if (props.type === 'profile') {
				value.value = value.value.replace(/[^A-Za-z0-9가-힣\u3131-\u318E]/g, '');
				lengthValue.value = INPUT_MAXLENGTH_TYPE.PROFILE;
			}
			emit('update:model-value', value.value);
		};
		const handleInput = (e: Event): void => {
			emit(`update:modelValue`, (e.target as HTMLInputElement).value);
		};
		const handleBlur = (e: Event): void => {
			emit(`blur`, (e.target as HTMLInputElement).value);
		};

		// const autoFocus = () => {
		// 	if (props.autofocus) {
		// 		const bannerInput = document.querySelector('.content-banner input') as HTMLInputElement; // 메인 페이지 배너에 포함된 서치바
		// 		const headerInput = document.querySelector('.header-search input') as HTMLInputElement; // 헤더에 포함된 서치바

		// 		if (storeManager.stateStore.headerVisible) {
		// 			const scrollY = window.scrollY;
		// 			bannerInput.focus();
		// 			window.scrollTo(0, scrollY);
		// 		} else {
		// 			// mobile
		// 			if (window.innerWidth >= 767) headerInput.focus();
		// 		}
		// 	}
		// };

		// watch(
		// 	() => storeManager.stateStore.headerVisible,
		// 	() => {
		// 		autoFocus();
		// 	},
		// );

		return {
			inputValue,
			storeManager,
			lengthValue,
			stopModelValue,
			handleBlur,
			handleKeyup,
			handleInput,
		};
	},
	unmounted() {
		this.stopModelValue();
	},
});
</script>

<template>
	<div class="apoc-input full-width">
		<input
			v-model="inputValue"
			:readonly="readOnly"
			:placeholder="placeholder"
			:type="type === 'profile' ? 'text' : type"
			:autocomplete="type === 'password' || type === 'email' ? 'on' : 'off'"
			:maxlength="lengthValue"
			@blur="handleBlur"
			@input="handleInput"
			@keyup="handleKeyup" />
	</div>
</template>

<style scoped></style>
