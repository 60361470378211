<script lang="ts">
import { defineComponent, nextTick, onBeforeUnmount, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import AppConfig from '@/constants';
import ApocLink from '@/components/common/ApocLink.vue';
import { loadLocalData, saveLocalData } from '@/utils/common-util';
import ApocImageSet from '@/components/common/ApocImageSet.vue';
import { UserInfoInterface } from '@/types';
import { getImgUrl } from '@/utils/common-util';
import { getApiClient } from '@/utils/apiClient';
import { generateAuthorToken } from '@/api/user';
import { POPUP_MODE } from '@/stores/state-store';
import { initStore } from '@/stores/store-manager';

export default defineComponent({
	name: 'MobileMenuPopup',
	components: { ApocLink, ApocImageSet },
	setup() {
		const { t } = useI18n();
		const router = useRouter();
		const storeManager = initStore();
		const lang = ref<string>('ko');
		const userInfo = ref<UserInfoInterface | null>(null);
		const apiClient = getApiClient(AppConfig.API_SERVER, storeManager);

		const closePopup = () => {
			storeManager.stateStore.setPopupMode(POPUP_MODE.NONE);
		};
		const changeLang = (v: string) => {
			lang.value = v;
			saveLocalData(AppConfig.KEYS.CONST.CHANGE_LANG, v);
			window.location.reload();
		};

		const clickGo = (v: string) => {
			router.push(v);
			closePopup();
		};
		const clickMake = () => {
			// window.alert(t('layout.preparing'));
			generateAuthorToken(apiClient, {}).then(res => {
				if (res.resultCode !== 0) {
					window.alert(t('msg.' + res.resultMsg));
				} else {
					storeManager.stateStore.setPopupMode(POPUP_MODE.NONE);
					nextTick(() => {
						storeManager.stateStore.setPopupMode(POPUP_MODE.MAKE_POPUP);
					});
				}
			});
		};

		const goToCs = () => {
			const locale = loadLocalData(AppConfig.KEYS.CONST.CHANGE_LANG);
			if (locale === 'ko') {
				window.open('https://forms.monday.com/forms/dc25f58eb654ded61f26e7da9135b539?r=use1');
			} else if (locale === 'en') {
				window.open('https://forms.monday.com/forms/2695ff52a02060d71bf427eb5b75fe7a?r=use1');
			}
		};

		onMounted(() => {
			const token = loadLocalData(AppConfig.KEYS.CONST.LOGIN_TOKEN);
			const user = loadLocalData(AppConfig.KEYS.CONST.LOGIN_USER);
			const loadLang = loadLocalData(AppConfig.KEYS.CONST.CHANGE_LANG);

			if (token && user) {
				storeManager.dataStore.setAuthToken(token);
				userInfo.value = JSON.parse(user);
			}
			if (loadLang) {
				lang.value = loadLang;
			}
			document.body.style.overflow = 'hidden';
		});

		onBeforeUnmount(() => {
			document.body.style.overflow = 'auto';
		});

		return {
			storeManager,
			t,
			lang,
			userInfo,
			getImgUrl,
			closePopup,
			changeLang,
			clickGo,
			clickMake,
			goToCs,
		};
	},
});
</script>

<template>
	<div class="mobile-popup-menu">
		<div>
			<div class="popup-user">
				<div v-if="userInfo">
					<span class="user-profile">
						<apoc-image-set :src="getImgUrl(userInfo?.userProfileImg)" @click="clickGo('/my-page')" />
						<div class="user-name-space">
							<span class="user-name" @click="clickGo('/my-page')">{{ userInfo?.userNicknm.trim() }}</span>
							<span class="follower-count">
								{{ t('layout.following') + ' ' }}{{ userInfo.followingCount ? userInfo.followingCount : 0 }}
								<i class="fa fa-regular fa-pipe" />
								{{ t('layout.follower') + ' ' }}{{ userInfo.followerCount ? userInfo.followerCount : 0 }}
							</span>
						</div>
					</span>
					<apoc-link class="make-btn" href="" @click="clickMake">{{ t('layout.make') }}</apoc-link>
				</div>
				<div v-else>
					<span>
						{{ t('layout.needLoginToUseService') }}
					</span>
					<div>
						<apoc-link class="mobile-btn" href="" @click="clickGo('/login')">{{ t('layout.login') }}</apoc-link>
						<apoc-link class="mobile-btn" href="" @click="clickGo('/login/join')">{{ t('layout.join') }}</apoc-link>
					</div>
				</div>
			</div>
			<!--				contents-->
			<div class="mobile-contents">
				<apoc-link class="contents-btn" href="" @click="clickGo('/')"> {{ t('page.Home') }}<i class="fa fa-regular fa-angle-right" /> </apoc-link>
				<apoc-link class="contents-btn" href="" @click="clickGo('/main')">
					{{ t('page.Contents') }}<i class="fa fa-regular fa-angle-right" />
				</apoc-link>
				<apoc-link class="contents-btn" href="" @click="clickGo('/template')">
					{{ t('page.Template') }}<i class="fa fa-regular fa-angle-right" />
				</apoc-link>
				<apoc-link class="contents-btn" href="" @click="clickGo('/community?type=UPDATE')">
					{{ t('page.Community') }}<i class="fa fa-regular fa-angle-right" />
				</apoc-link>
				<apoc-link class="contents-btn" href="" @click="goToCs">
					{{ t(`layout.customerService`) }}<i class="fa fa-regular fa-angle-right" />
				</apoc-link>
			</div>
		</div>
		<div class="mobile-language">
			<apoc-link :class="lang === 'ko' ? 'active' : 'non-active'" href="#" @click="changeLang('ko')">
				<apoc-image-set v-if="lang === 'ko'" :img-sets="2" :src="'assets/images/lang/lang_ko_click.webp'" />
				<apoc-image-set v-else :img-sets="2" :src="'assets/images/lang/lang_ko_gray.webp'" />
				{{ t('ko') }}
			</apoc-link>
			<apoc-link :class="lang === 'en' ? 'active' : ''" href="#" @click="changeLang('en')">
				<i class="fa fa-regular fa-globe" />{{ t('en') }}
			</apoc-link>
		</div>
	</div>
</template>
<style scoped></style>
