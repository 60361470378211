<script lang="ts">
import { updateContentEval } from '@/api/content';
import { FavEntityDto } from '@/api/dto/fav.dto';
import { deleteFav, insertFav } from '@/api/fav';
import ApocImageSet from '@/components/common/ApocImageSet.vue';
import ApocLink from '@/components/common/ApocLink.vue';
import AppConfig from '@/constants';
import { POPUP_MODE } from '@/stores/state-store';
import { initStore } from '@/stores/store-manager';
import { CONTENT_EVAL_LIKEBAD_CODE, CONTENT_EVAL_TYPE_CODE, FAV_TYPE_CODE, STATE_YN } from '@/types';
import { getApiClient } from '@/utils/apiClient';
import { generateShortShareLink, loadLocalData } from '@/utils/common-util';
import { defineComponent, onBeforeUnmount, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { ContentEvalEntity } from '@/api/model/content.entity';

export default defineComponent({
	name: 'ContentsMoreModal',
	components: { ApocImageSet, ApocLink },
	setup() {
		const { t } = useI18n({ useScope: 'global' });
		const storeManager = initStore();
		const bookmarkImg = ref<string>('');
		const router = useRouter();
		const contentIdx = ref<string>(storeManager.dataStore.popupContentId);
		const apiClient = getApiClient(AppConfig.API_SERVER, storeManager);
		const isFav = ref<STATE_YN | null>(storeManager.dataStore.isFav ? storeManager.dataStore.isFav : null);
		const isHate = ref<STATE_YN | null | undefined>(storeManager.dataStore.isHate ? storeManager.dataStore.isHate : null);

		const onClickShare = async () => {
			generateShortShareLink(storeManager.dataStore.popupShortUrl as string);

			if (!storeManager.stateStore.popupCopyState) {
				storeManager.stateStore.setPopupCopyState(true);
				setTimeout(() => {
					storeManager.stateStore.setPopupCopyState(false);
				}, 3000);
			}
		};

		//팝업 닫기
		const closePopup = (): void => {
			storeManager.stateStore.setPopupMode(POPUP_MODE.NONE);
		};

		const onClickReport = () => {
			storeManager.stateStore.setPopupMode(POPUP_MODE.CONTENT_REPORT_POPUP);
		};

		const onClickCopyright = () => {
			storeManager.stateStore.setPopupMode(POPUP_MODE.COPYRIGHT_POPUP);
		};

		// 로그인 회원 확인
		const checkLoginUser = () => {
			if (loadLocalData(AppConfig.KEYS.CONST.LOGIN_USER) && loadLocalData(AppConfig.KEYS.CONST.LOGIN_TOKEN)) {
				return true;
			} else {
				window.alert(t('msg.ALERT_JUST_LOGIN'));
				router.push('/login');
				return false;
			}
		};

		const onClickStore = async function () {
			if (checkLoginUser()) {
				if (isFav.value === STATE_YN.Y) {
					const param = new FavEntityDto();
					param.targetIdx = contentIdx.value;
					param.favTypeCode = FAV_TYPE_CODE.CONTENT;
					try {
						deleteFav(apiClient, param).then(res => {
							if (res.resultCode === 0) {
								isFav.value = null;
								storeManager.dataStore.setIsFav(null);
							}
						});
					} catch (err) {
						window.alert(err);
					}
				} else {
					const param = new FavEntityDto();
					param.targetIdx = contentIdx.value;
					param.favTypeCode = FAV_TYPE_CODE.CONTENT;
					try {
						insertFav(apiClient, param).then(res => {
							if (res.resultCode === 0) {
								isFav.value = STATE_YN.Y;
								storeManager.dataStore.setIsFav(STATE_YN.Y);
							}
						});
					} catch (err) {
						window.alert(err);
					}
				}
			}
		};

		// 바텀시트의 '싫어요' 버튼 클릭 시 실행 함수
		const onClickContentEval = () => {
			if (checkLoginUser()) {
				const param = new ContentEvalEntity();
				param.contentEvalTargetIdx = contentIdx.value;
				param.contentEvalTypeCode = CONTENT_EVAL_TYPE_CODE.LIKEBAD;
				param.contentEvalValue = CONTENT_EVAL_LIKEBAD_CODE.BAD;
				updateContentEval(apiClient, param).then(res => {
					if (res.resultCode !== 0) {
						window.alert(t('msg.' + res.resultMsg));
					} else {
						if (res.data?.delYn === STATE_YN.Y) {
							isHate.value = undefined;
							storeManager.dataStore.setIsHate(undefined);
							(window as any)?.tracker.sendEvent('CONTBADCANC', res.data);
						} else {
							isHate.value = STATE_YN.Y;
							storeManager.dataStore.setIsHate(STATE_YN.Y);
							(window as any)?.tracker.sendEvent('CONTBAD', res.data);
						}
					}
				});
			}
		};

		onMounted(() => {
			// document.body.style.overflow = 'hidden';
			// document.body.style.position = 'fixed';
			document.body.style.height = '100%';
			document.body.style.width = '100%';
		});

		onBeforeUnmount(() => {
			document.body.style.overflow = 'auto';
			// document.body.style.position = 'relative';
			document.body.style.height = 'unset';
			document.body.style.width = 'unset';
		});

		return {
			bookmarkImg,
			storeManager,
			t,
			isFav,
			closePopup,
			onClickReport,
			onClickCopyright,
			onClickStore,
			onClickShare,
			onClickContentEval,
			isHate,
		};
	},
});
</script>
<template>
	<div class="contents-select-modal contents-more-modal">
		<div class="action-box">
			<div class="header-bar">
				<div class="bar"></div>
			</div>
			<apoc-link
				v-if="storeManager.stateStore.contentsMorePlus && storeManager.dataStore.bottomSheetNum === 4"
				href="#"
				@click="onClickContentEval()">
				<i :class="isHate ? 'isActive fa-solid' : ' fa-light '" class="fa fa-heart-crack" />
				{{ t('common.hate') }}
			</apoc-link>
			<apoc-link v-if="storeManager.stateStore.contentsMorePlus && storeManager.dataStore.bottomSheetNum >= 3" href="#" @click="onClickStore">
				<apoc-image-set :src="isFav ? '/assets/images/common/icons/icon_save.svg' : '/assets/images/common/icons/icon_noSave.svg'"></apoc-image-set>
				{{ t('common.save') }}
			</apoc-link>
			<apoc-link v-if="storeManager.stateStore.contentsMorePlus && storeManager.dataStore.bottomSheetNum >= 2" href="#" @click="onClickShare">
				<apoc-image-set src="/assets/images/common/icons/icon_share.svg"></apoc-image-set>
				{{ t('common.share') }}
			</apoc-link>
			<apoc-link v-if="storeManager.stateStore.contentsMorePlus && storeManager.dataStore.bottomSheetNum >= 1" href="#" @click="onClickCopyright">
				<apoc-image-set src="/assets/images/common/icons/icon_exclamation_circle.svg"></apoc-image-set>
				{{ t('common.source') }}
			</apoc-link>
			<apoc-link href="#" @click="onClickReport">
				<apoc-image-set src="/assets/images/common/icons/icon_placeholder.svg"></apoc-image-set>
				{{ t('layout.myContents.report') }}
			</apoc-link>
		</div>
	</div>
</template>
