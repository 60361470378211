<script lang="ts">
import { UserInfoEntityDto } from '@/api/dto/user.dto';
import { doLogout, getUserInfo } from '@/api/user';
import ApocButton from '@/components/common/ApocButton.vue';
import ApocLink from '@/components/common/ApocLink.vue';
import ApocSearch from '@/components/common/ApocSearch.vue';
import ContentsOptionSelect from '@/components/common/ContentsOptionSelect.vue';
import HeaderSelect from '@/components/common/HeaderSelect.vue';
import type { HeaderSelectOption } from '@/components/common/apocSelect';
import AppConfig, { APP_ENV_TYPE } from '@/constants';
import { THIRD_PARTY_TYPE } from '@/stores/common-store';
import { RESOLUTION_TYPE } from '@/stores/data-store';
import { HEADER_TYPE, POPUP_MODE } from '@/stores/state-store';
import { initStore } from '@/stores/store-manager';
import { getApiClient } from '@/utils/apiClient';
import { goAuthor } from '@/utils/apoc-utils';
import { loadLocalData, removeLocalData, saveLocalData } from '@/utils/common-util';
import type { PropType } from 'vue';
import { computed, defineComponent, nextTick, onMounted, onUpdated, ref, watch } from 'vue';
import { config as gtagConfig } from 'vue-gtag';
import { useI18n } from 'vue-i18n';
import { useRoute, useRouter } from 'vue-router';
import ApocImageSet from '@/components/common/ApocImageSet.vue';
import { loadApiGauge } from '@/utils/utils';
import { LOADING_TYPE } from '@/types/index';

export default defineComponent({
	name: 'MainHeader',
	components: { ApocLink, ApocButton, ApocSearch, HeaderSelect, ContentsOptionSelect, ApocImageSet },
	props: {
		modelValue: {
			type: String as PropType<string>,
		},
		isContentHeaderVisible: {
			type: Boolean as PropType<boolean>,
			default: false,
		},
	},
	setup(props, { emit }) {
		const { t } = useI18n({ useScope: 'global' });
		const router = useRouter();
		const route = useRoute();
		const storeManager = initStore();
		const apiClient = computed(() => getApiClient(AppConfig.API_SERVER, storeManager));
		const isOpenMenu = ref<boolean>(false);
		const userInfo = ref<UserInfoEntityDto | null>(null);
		const lang = ref<string>('ko');

		//Select - 언어
		const langOptions = [
			{
				value: 'ko',
				label: t('lang.ko'),
				subLabel: t('KO'),
				func: () => {
					changeLang('ko');
				},
			},
			{
				value: 'en',
				label: t('lang.en'),
				subLabel: t('EN'),
				func: () => {
					changeLang('en');
				},
			},
		];

		const myMenuOptions = ref<HeaderSelectOption[]>([
			{
				value: 'profile-change',
				label: t('layout.header.my.profileChange'),
				router: '/my-profile',
			},
			{
				value: t('layout.header.myPage'),
				label: t('layout.header.myPage'),
				router: '/my-page',
				imageSrc: '/assets/images/common/icons/header/icon_mypage.svg',
			},
			{
				value: t('layout.header.my.myContents'),
				label: t('layout.header.my.myContents'),
				router: '/my-contents',
				imageSrc: '/assets/images/common/icons/header/icon_draw-polygon2.svg',
			},
			{
				value: t('layout.header.my.save'),
				label: t('layout.header.my.save'),
				router: '/my-page?tab=save',
				imageSrc: '/assets/images/common/icons/header/icon_bookmark.svg',
			},
			{
				value: t('layout.header.my.follwing'),
				label: t('layout.header.my.follwing'),
				router: '/my-page?tab=following',
				imageSrc: '/assets/images/common/icons/header/icon_people.svg',
			},
			{
				value: 'studio',
				label: t('layout.header.my.studio'),
				imageSrc: '/assets/images/common/icons/header/icon_placeholder.svg',
				func: () => {
					onCheckLoginUser();
				},
			},
			{
				value: t('layout.header.my.infoChange'),
				label: t('layout.header.my.infoChange'),
				router: '/my-page/edit',
				imageSrc: '/assets/images/common/icons/header/icon_setting.svg',
			},
			{
				value: t('layout.header.my.logout'),
				label: t('layout.header.my.logout'),
				imageSrc: '/assets/images/common/icons/header/icon_logout.svg',
				func: () => {
					onClickLogout();
				},
			},
		]);

		const changeLang = (v: string) => {
			lang.value = v;
			saveLocalData(AppConfig.KEYS.CONST.CHANGE_LANG, v);
			window.location.reload();
		};

		const onClickLogout = () => {
			if (storeManager.dataStore.authToken) {
				doLogout(apiClient.value, {}).then(res => {
					if (res.resultCode === 0) {
						removeLocalData(AppConfig.KEYS.CONST.LOGIN_TOKEN);
						removeLocalData(AppConfig.KEYS.CONST.LOGIN_USER);
						storeManager.dataStore.setAuthToken('');
						(window as any)?.tracker.setLoginUserId('');
						if (storeManager.commonStore.thirdPartyType === THIRD_PARTY_TYPE.MS_TEAMS) {
							const param = {
								authorUrl: `${AppConfig.AUTHOR_HOST}author?a=logout`,
								ctx: JSON.stringify({ logout: true }),
							};
							goAuthor(param, storeManager);
						} else {
							// 기존 로그아웃처리
							if (storeManager.stateStore.openAuthor) {
								// 로그아웃 방식 변경
								storeManager.stateStore.setOpenAuthor(false);
								window.open(`${AppConfig.AUTHOR_HOST}author?a=logout`, 'logout');
							}
						}
						window.location.replace('/');
					}
				});
			}
		};

		const updateAuthSession = (token: string) => {
			let msg = '';
			if (token) msg = window.btoa(token);
			let sessionIframe = window.document.getElementById('session-iframe');
			if (!sessionIframe) {
				sessionIframe = window.document.createElement('iframe');
				sessionIframe.setAttribute('id', 'session-iframe');
				const bodyElList = window.document.getElementsByTagName('body');
				if (bodyElList.length > 0) bodyElList[0].appendChild(sessionIframe);
			}
			const targetUrl = `${AppConfig.AUTHOR_HOST}updateSession?a=${msg}&lang=${loadLocalData(AppConfig.KEYS.CONST.CHANGE_LANG)}`;
			const iframeWindow = (sessionIframe as HTMLIFrameElement).contentWindow;
			if (iframeWindow) {
				iframeWindow.location.replace(targetUrl);
			}
		};

		const updateUserInfo = () => {
			storeManager.dataStore.addPageApiTotalCount(1);
			getUserInfo(apiClient.value, {})
				.then(res => {
					storeManager.dataStore.addLoadedCount(1);
					// console.log('getUserInfo: ', storeManager.dataStore.pageApiTotalCount, storeManager.dataStore.loadedCount);
					loadApiGauge(LOADING_TYPE.ROLL, '', '');
					if (res.resultCode === 0) {
						if (res.data) {
							saveLocalData(AppConfig.KEYS.CONST.LOGIN_USER, JSON.stringify(res.data));
							userInfo.value = res.data;
							myMenuOptions.value[0].label = userInfo.value.userNicknm as string;
							const token = loadLocalData(AppConfig.KEYS.CONST.LOGIN_TOKEN);
							updateAuthSession(token ?? '');
						}
					} else if (res.resultCode === 91) {
						removeLocalData(AppConfig.KEYS.CONST.LOGIN_TOKEN);
						removeLocalData(AppConfig.KEYS.CONST.LOGIN_USER);
						storeManager.dataStore.setAuthToken('');
						updateAuthSession('');
					}
				})
				.catch(e => {
					storeManager.dataStore.addLoadedCount(1);
					loadApiGauge(LOADING_TYPE.ROLL, '', '');
				});
		};

		//  * 로그인 체크
		const onCheckLoginUser = () => {
			const token = loadLocalData(AppConfig.KEYS.CONST.LOGIN_TOKEN);
			const user = loadLocalData(AppConfig.KEYS.CONST.LOGIN_USER);
			const scrollWidth = document.documentElement.clientWidth + 'px';
			if (token && user) {
				// window.document.body.style.width = scrollWidth;
				// window.document.getElementsByTagName('header')[0].style.width = scrollWidth;
				// storeManager.stateStore.setPopupMode(POPUP_MODE.MAKE_POPUP);
				// ctx : ms-teams 화면 이동을 위한 값
				const param = {
					authorUrl: `${AppConfig.AUTHOR_HOST}author` + `?lang=${loadLocalData(AppConfig.KEYS.CONST.CHANGE_LANG)}&destination=main`,
					ctx: JSON.stringify({
						lang: loadLocalData(AppConfig.KEYS.CONST.CHANGE_LANG),
						isNew: true,
					}),
				};
				goAuthor(param, storeManager);
			} else {
				window.alert(t('msg.ALERT_JUST_LOGIN'));
				router.push({ path: '/login', query: { redirect: 'apoc-author' } });
			}
		};

		//mobile - lang클릭
		const clickMoreBtn = () => {
			if (storeManager.dataStore.page.resolution <= RESOLUTION_TYPE.MOBILE) {
				// storeManager.dataStore.setPopupContentId(myContents.value.contentIdx);
				// storeManager.dataStore.setPopupShortUrl(myContents.value.shortUrl as string);
				storeManager.stateStore.setPopupMode(POPUP_MODE.LANG_SELECT_MODAL);
			}
		};

		watch(
			() => storeManager.dataStore.authToken,
			(current, prev) => {
				if (current !== prev) {
					if (current) {
						updateUserInfo();
					} else {
						userInfo.value = null;
						updateAuthSession('');
					}
				}
			},
		);

		watch(
			() => storeManager.commonStore.thirdPartyType,
			() => {
				if (storeManager.commonStore.thirdPartyType === THIRD_PARTY_TYPE.CAFE_24) {
				}
				// todo : eng logo 변경 예정
				// headerLogoUrl.value = 'assets/images/cafe24/cafe24_logo.webp';
			},
		);

		onMounted(() => {
			updateUserInfo();
			const token = loadLocalData(AppConfig.KEYS.CONST.LOGIN_TOKEN);
			if (token) {
				storeManager.dataStore.setAuthToken(token);
				if (AppConfig.ENV === APP_ENV_TYPE.PROD) gtagConfig({ user_id: userInfo.value?.userIdx });

				nextTick(() => {
					updateUserInfo();
				});
			}
			const loadLang = loadLocalData(AppConfig.KEYS.CONST.CHANGE_LANG);
			if (loadLang) {
				lang.value = loadLang;
			}
		});

		onUpdated(() => {
			const token = loadLocalData(AppConfig.KEYS.CONST.LOGIN_TOKEN);
			const user = loadLocalData(AppConfig.KEYS.CONST.LOGIN_USER);
			if (storeManager.dataStore.authToken === '') {
				if (token && user) {
					storeManager.dataStore.setAuthToken(token);
					userInfo.value = JSON.parse(user);
				}
			}
		});

		return {
			t,
			myMenuOptions,
			isOpenMenu,
			router,
			route,
			userInfo,
			storeManager,
			RESOLUTION_TYPE,
			HEADER_TYPE,
			langOptions,
			lang,
			onCheckLoginUser,
			clickMoreBtn,
		};
	},
});
</script>

<template>
	<!-- main 헤더 -->
	<ClientOnly>
		<header v-show="isContentHeaderVisible">
			<div class="header content-header">
				<div class="header-logo">
					<object
						:data="
							storeManager.dataStore.page.resolution >= RESOLUTION_TYPE.S_PC
								? '/assets/images/common/logo/logo-pc-white.svg'
								: storeManager.dataStore.page.resolution >= RESOLUTION_TYPE.TABLET
									? '/assets/images/common/logo/logo-tablet-white.svg'
									: '/assets/images/common/logo/logo-mobile-white.svg'
						" />
					<div class="link-area">
						<apoc-link href="/"></apoc-link>
					</div>
				</div>
				<div class="right-menu">
					<contents-option-select
						v-if="storeManager.dataStore.page.resolution > RESOLUTION_TYPE.MOBILE"
						class="header-lang-select"
						:is-active="true"
						:option-list="langOptions"
						:placeholder="lang === 'ko' ? t('lang.ko') : t('lang.en')"
						:except-placeholder-from-list="true"
						arrow-img-src="/assets/images/common/icons/header/arrow_down_white.svg" />
					<contents-option-select
						v-else
						class="header-lang-select"
						:is-active="true"
						:option-list="langOptions"
						:placeholder="lang === 'ko' ? t('lang.ko') : t('lang.en')"
						:except-placeholder-from-list="true"
						arrow-img-src="/assets/images/common/icons/header/arrow_down_white.svg"
						:disabled="true"
						@click="clickMoreBtn" />
					<header-select v-if="userInfo" class="my-page-menu" :option-list="myMenuOptions" :is-content-header="true" />
					<apoc-button v-else class="my-page-menu login-btn" :on-click="() => router.push('/login')">{{ t(`layout.header.login`) }}</apoc-button>
					<apoc-button class="make-btn" :is-primary="true" :on-click="onCheckLoginUser">{{ t(`layout.header.make`) }}</apoc-button>
				</div>
			</div>
		</header>
		<!-- 그 외 전체 헤더 -->
		<header v-show="!isContentHeaderVisible">
			<div v-if="storeManager.dataStore.page.resolution > RESOLUTION_TYPE.TABLET" class="header">
				<div class="header-logo">
					<object
						:data="
							storeManager.dataStore.page.resolution >= RESOLUTION_TYPE.S_PC
								? '/assets/images/common/logo/logo-pc.svg'
								: storeManager.dataStore.page.resolution >= RESOLUTION_TYPE.TABLET
									? '/assets/images/common/logo/logo-tablet.svg'
									: '/assets/images/common/logo/logo-mobile.svg'
						" />
					<div class="link-area">
						<apoc-link href="/"></apoc-link>
					</div>
				</div>
				<div>
					<apoc-search class="header-search" :placeholder="t(`common.search`)" :model-value="modelValue" />
				</div>
				<div class="right-menu">
					<contents-option-select
						class="header-lang-select"
						:is-active="true"
						:option-list="langOptions"
						:placeholder="lang === 'ko' ? t('lang.ko') : t('lang.en')"
						:except-placeholder-from-list="true" />
					<header-select v-if="userInfo" class="my-page-menu" :option-list="myMenuOptions" />
					<apoc-button v-else class="my-page-menu login-btn" :on-click="() => router.push('/login')">{{ t(`layout.header.login`) }}</apoc-button>
					<apoc-button class="make-btn" :is-primary="true" :on-click="onCheckLoginUser">{{ t(`layout.header.make`) }}</apoc-button>
				</div>
			</div>
			<!-- 태블릿 헤더 -->
			<div v-else-if="storeManager.dataStore.page.resolution >= RESOLUTION_TYPE.TABLET" class="header">
				<div class="header-logo">
					<object
						:data="
							storeManager.dataStore.page.resolution >= RESOLUTION_TYPE.S_PC
								? '/assets/images/common/logo/logo-pc.svg'
								: storeManager.dataStore.page.resolution >= RESOLUTION_TYPE.TABLET
									? '/assets/images/common/logo/logo-tablet.svg'
									: '/assets/images/common/logo/logo-mobile.svg'
						" />
					<div class="link-area">
						<apoc-link href="/"></apoc-link>
					</div>
				</div>
				<div>
					<apoc-search class="header-search" :placeholder="t(`common.search`)" :model-value="modelValue" />
				</div>
				<div class="right-menu">
					<header-select v-if="userInfo" class="my-page-menu" :option-list="myMenuOptions" />
					<apoc-button v-else class="my-page-menu login-btn" :on-click="() => router.push('/login')">{{ t(`layout.header.login`) }}</apoc-button>
				</div>
			</div>
			<!-- 모바일 헤더 -->
			<div v-else-if="storeManager.stateStore.headerState === HEADER_TYPE.DEFAULT" class="header mobile-header">
				<div class="header-logo"><object data="/assets/images/common/logo/logo-mobile.svg" /><apoc-link href="/"></apoc-link></div>
				<div class="right-menu">
					<contents-option-select
						class="header-lang-select"
						:is-active="true"
						:option-list="langOptions"
						:placeholder="lang === 'ko' ? t('lang.ko') : t('lang.en')"
						:except-placeholder-from-list="true"
						:disabled="true"
						@click="clickMoreBtn" />
					<header-select v-if="userInfo" class="my-page-menu" :option-list="myMenuOptions" />
					<apoc-button v-else class="my-page-menu login-btn" :on-click="() => router.push('/login')">{{ t(`layout.header.login`) }}</apoc-button>
				</div>
			</div>
			<div v-else-if="storeManager.stateStore.headerState === HEADER_TYPE.BACK_OPTION" class="header mobile-header">
				<div class="header-logo">
					<apoc-image-set class="header-arrow" src="/assets/images/common/icons/header/icon_left_arrow.svg" @click="() => router.go(-1)" />
				</div>
				<div class="header-title">{{ t(`${storeManager.dataStore.headerTitle}`) }}</div>
				<div class="right-menu">
					<contents-option-select
						class="header-lang-select"
						:is-active="true"
						:option-list="langOptions"
						:placeholder="lang === 'ko' ? t('lang.ko') : t('lang.en')"
						:except-placeholder-from-list="true"
						:disabled="true"
						@click="clickMoreBtn" />
					<header-select v-if="userInfo" class="my-page-menu" :option-list="myMenuOptions" />
					<apoc-button v-else class="my-page-menu login-btn" :on-click="() => router.push('/login')">{{ t(`layout.header.login`) }}</apoc-button>
				</div>
			</div>
			<div v-else-if="storeManager.stateStore.headerState === HEADER_TYPE.BACK_TITLE" class="header mobile-header back-title">
				<div class="header-logo">
					<apoc-image-set class="header-arrow" src="/assets/images/common/icons/header/icon_left_arrow.svg" @click="() => router.go(-1)" />
				</div>
				<div class="header-title">{{ storeManager.dataStore.headerTitle }}</div>
			</div>
			<div v-else-if="storeManager.stateStore.headerState === HEADER_TYPE.BACK_MENU" class="header mobile-header back-title">
				<div class="header-logo">
					<apoc-image-set class="header-arrow" src="/assets/images/common/icons/header/icon_left_arrow.svg" @click="() => router.go(-1)" />
				</div>
				<div class="right-menu">
					<contents-option-select
						class="header-lang-select"
						:is-active="true"
						:option-list="langOptions"
						:placeholder="lang === 'ko' ? t('lang.ko') : t('lang.en')"
						:except-placeholder-from-list="true"
						:disabled="true"
						@click="clickMoreBtn" />
					<header-select v-if="userInfo" class="my-page-menu" :option-list="myMenuOptions" />
					<apoc-button v-else class="my-page-menu login-btn" :on-click="() => router.push('/login')">{{ t(`layout.header.login`) }}</apoc-button>
				</div>
			</div>
		</header>
	</ClientOnly>
</template>

<style scoped></style>
