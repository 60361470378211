import { loadLocalData, removeLocalData } from '@/utils/common-util';
import { createPinia } from 'pinia';
import { ViteSSG } from 'vite-ssg';
import type { I18nOptions } from 'vue-i18n';
import VueClickAway from 'vue3-click-away';

import AppConfig, { APP_ENV_TYPE } from '@/constants';
import { en, ko, setupI18n } from '@/lang';
import { StoreMutType } from '@/stores/state-store';
import { initStore } from '@/stores/store-manager';
import type { UserInfoInterface } from '@/types/index';
import '@egjs/vue3-flicking/dist/flicking-inline.css';
import '@egjs/vue3-flicking/dist/flicking.css';
import Vue3Lottie from 'vue3-lottie';
import App from './App.vue';
import { routesList } from './router';
// import routes from 'virtual:generated-pages'
import { setLanguage } from '@/utils/common-util';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import VueGtag from 'vue-gtag';
import { PublicClientApplication, LogLevel } from '@azure/msal-browser';

if (typeof window !== 'undefined') {
	import('./pwa');
}

// i18n 설정
let lang = loadLocalData(AppConfig.KEYS.CONST.CHANGE_LANG);
if (!lang) {
	setLanguage(Intl.DateTimeFormat().resolvedOptions().locale);
	lang = loadLocalData(AppConfig.KEYS.CONST.CHANGE_LANG);
}

const i18nConfig: I18nOptions = {
	legacy: false,
	missingWarn: false,
	fallbackWarn: false,
	warnHtmlMessage: false,
	fallbackLocale: 'en',
	locale: lang ? lang : 'en',
	messages: {
		ko,
		en,
	},
};
const i18n = setupI18n(i18nConfig);

const firebaseConfig = {
	apiKey: 'AIzaSyA6zcn_M-3JeLlh22-nQ_QkoZBWxoKFrGI',
	authDomain: 'apoc-3fb69.firebaseapp.com',
	projectId: 'apoc-3fb69',
	storageBucket: 'apoc-3fb69.appspot.com',
	messagingSenderId: '442216788989',
	appId: '1:442216788989:web:71831c5725148304b05f94',
	measurementId: 'G-4WBFVCPG8C',
};
firebase.initializeApp(firebaseConfig);

const msalConfig = {
	auth: {
		clientId: 'fb996024-62bb-45d1-a739-e3ca879a578c',
		authority: 'https://login.microsoftonline.com/common/',
		knownAuthorities: [],
		cloudDiscoveryMetadata: '',
		redirectUri: `${AppConfig.FRONT_HOST}`,
	},
	system: {
		// loggerOptions: {
		// 	loggerCallback: (level: LogLevel, message: string, containsPii: boolean) => {
		// 		if (containsPii) {
		// 			return;
		// 		}
		// 		switch (level) {
		// 			case LogLevel.Error:
		// 				console.error(message);
		// 				return;
		// 			case LogLevel.Info:
		// 				console.info(message);
		// 				return;
		// 			case LogLevel.Verbose:
		// 				console.debug(message);
		// 				return;
		// 			case LogLevel.Warning:
		// 				console.warn(message);
		// 				return;
		// 		}
		// 	},
		// },
	},
};

export const msalInstance = new PublicClientApplication(msalConfig);
(async () => {
	if (msalInstance) await msalInstance.initialize();
})();

// index.html에 tracker.js 파일 추가
if (typeof document !== 'undefined') {
	const trackerScript = document.createElement('script');
	trackerScript.src = AppConfig.TRACKER_URL;
	document.body.appendChild(trackerScript);
}

export const createApp = ViteSSG(
	App,
	{
		routes: routesList,
		scrollBehavior: (to, from, savedPosition) => {
			if (!to.path.indexOf('search')) {
				document.getElementById('app')?.scrollIntoView({ behavior: 'auto' });
			}
		},
	},
	({ app, router, routes, isClient, initialState }) => {
		const pinia = createPinia();
		//@ts-ignore
		app.use(pinia).use(VueClickAway).use(i18n).use(Vue3Lottie);

		if (AppConfig.ENV === APP_ENV_TYPE.PROD) {
			app.use(
				VueGtag,
				{
					// @ts-ignore
					pageTrackerTemplate: function (to: any) {
						return {
							page_title:
								// @ts-ignore
								i18n.global.t('page.defaultTitle') + (to.name !== '' ? ' - ' + i18n.global.t(`page.${to.name}`) : ''),
							page_path: to.path,
						};
					},
					config: {
						id: 'G-P87D4JKSPC',
						params: {
							send_page_view: false,
						},
					},
					pageTrackerScreenviewEnabled: true,
				},
				router,
			);
		}

		if (import.meta.env.SSR) initialState.pinia = pinia.state.value;
		else pinia.state.value = initialState.pinia || {};

		router.beforeEach((to, from, next) => {
			let userInfo: UserInfoInterface | undefined = undefined;
			const storeManager = initStore();
			const user = loadLocalData(AppConfig.KEYS.CONST.LOGIN_USER);
			const token = loadLocalData(AppConfig.KEYS.CONST.LOGIN_TOKEN);
			storeManager.dataStore.setPageApiTotalCount(0);
			storeManager.dataStore.setLoadedCount(0);
			storeManager.stateStore.setLoadingState(false);
			if (user && token) {
				userInfo = JSON.parse(user);
				storeManager.dataStore.setLoginUserUuid(userInfo?.userIdx as string);
				storeManager.dataStore.setAuthToken(token);
			} else {
				removeLocalData(StoreMutType.loginUserUuid);
				removeLocalData(StoreMutType.setAuthToken);
				storeManager.dataStore.setLoginUserUuid('');
				storeManager.dataStore.setAuthToken('');
			}
			// hash값 대응 redirect
			if (to.fullPath.includes('#/')) {
				next({ path: to.fullPath.replace('#/', '') });
			} else {
				next();
			}
		});
		router.afterEach((to, from) => {
			// @ts-ignore
			if (typeof window !== 'undefined' && !window.tracker) {
				// @ts-ignore
				(window as any).tracker = new window.ApocTracker('APOC');
			}
			// @ts-ignore
			if (typeof window !== 'undefined') {
				(window as any)?.tracker?.sendEvent('CHANGEPAGE', '');
			}
		});

		router.onError(e => {
			console.error('msg: ', e);
		});
	},
);
