const COLOR_LIST = [];

class Circle {
	private x: number;
	private y: number;
	private size: number;
	private moveScale: number;
	private context: CanvasRenderingContext2D;
	private direction: number;
	private color: number;
	private ratShow = 0;
	private colorAlpha: number;

	constructor(context: CanvasRenderingContext2D, x: number, y: number) {
		this.x = x;
		this.y = y;
		this.size = 200 + Math.random() * 600;
		this.moveScale = Math.random() * 3;
		this.context = context;
		this.color = Math.floor(Math.random() * 3);
		this.colorAlpha = Math.random() * 0.2 + 0.1;
		const tx = Math.random() * context.canvas.width;
		const ty = Math.random() * context.canvas.height;
		this.direction = Math.atan((this.x - tx) / (this.y - ty));
	}

	draw() {
		try {
			this.context.beginPath();
			const rSize = this.size / 2;
			const radgrad = this.context.createRadialGradient(this.x, this.y, 0, this.x, this.y, rSize);
			const color1 = this.colorAlpha * this.ratShow;
			const color2 = color1 * 0.5;
			switch (this.color) {
				case 2:
					radgrad.addColorStop(0, `rgba(253, 186, 9, ${color1})`); // 0.7
					radgrad.addColorStop(0.5, `rgba(253, 186, 9, ${color2})`);
					radgrad.addColorStop(1, 'rgba(253, 186, 9, 0)');
					break;
				case 1:
					radgrad.addColorStop(0, `rgba(192, 110, 243, ${color1})`); // 0.7
					radgrad.addColorStop(0.5, `rgba(192, 110, 243, ${color2})`);
					radgrad.addColorStop(1, 'rgba(192, 110, 243, 0)');
					break;
				default:
					radgrad.addColorStop(0, `rgba(113, 136, 255, ${color1})`);
					radgrad.addColorStop(0.5, `rgba(113, 136, 255, ${color2})`);
					radgrad.addColorStop(1, 'rgba(113, 136, 255, 0)');
					break;
			}
			this.context.fillStyle = radgrad;
			this.context.fillRect(this.x - this.size / 2, this.y - this.size / 2, this.size, this.size);
			if (this.ratShow < 1) this.ratShow += 0.1;
			this.x = this.x + this.moveScale * Math.cos(this.direction);
			this.y = this.y + this.moveScale * Math.sin(this.direction);
		} catch (e) {
			console.error(e);
		}
	}

	isExpire() {
		return this.x - this.size / 2 > this.context.canvas.width || this.x + this.size / 2 < 0;
	}
}

export class BrainBackground {
	private canvas: HTMLCanvasElement;
	private context: CanvasRenderingContext2D | null;
	private circleList: Circle[] = [];
	private isEnable = true;

	constructor(canvas: HTMLCanvasElement, num = 7) {
		this.canvas = canvas;
		this.context = canvas.getContext('2d');

		this.resize = this.resize.bind(this);
		this.draw = this.draw.bind(this);

		this.resize();

		if (this.context) {
			for (let index = 0; index < num; index++) {
				const x = Math.random() * this.canvas.width;
				const y = Math.random() * this.canvas.height;
				this.circleList.push(new Circle(this.context, x, y));
			}
		}

		window.addEventListener('resize', this.resize);

		this.draw();
	}

	destroy() {
		this.isEnable = false;
		this.circleList = [];
		window.removeEventListener('resize', this.resize);
	}

	resize() {
		this.canvas.width = window.innerWidth;
		this.canvas.height = window.innerHeight;
	}

	draw() {
		if (!this.isEnable) return;
		this.context?.clearRect(0, 0, this.canvas.width, this.canvas.height);
		const newList = [];
		for (let index = 0, count = this.circleList.length; index < count; index++) {
			if (!this.circleList[index].isExpire()) {
				newList.push(this.circleList[index]);
			} else {
				const x = Math.random() * this.canvas.width;
				const y = Math.random() * this.canvas.height;
				if (this.context) newList.push(new Circle(this.context, x, y));
			}
		}
		this.circleList = newList;
		for (let index = 0, count = this.circleList.length; index < count; index++) {
			this.circleList[index].draw();
		}
		window.requestAnimationFrame(this.draw);
	}
}
